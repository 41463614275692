import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import "./cardView.css";
import inika_logo from "../Inika_logo.png";
import tag_demo from "../tagDemo.png";
import search_demo from "../searchDemo.png";
import chat_demo from "../chatDemo.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";
import axios from "axios";

export const Demo = (props) => {
  const { Meta } = Card;
  const history = useHistory();
  const [userRole, setUserRole] = useState("upload");
  const [refreshRole, setRefreshRole] = useState([]);
  const handleClickSearch = async () => {
    history.push("/inikaSearch");
  };
  const handleUserRole = async () => {
    if (auth.currentUser.uid) {
      const userRoleFetched = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/get-user-role",
        {
          user: auth.currentUser.uid,
        }
      );
      setUserRole(userRoleFetched.data.role);
    }
  };

  const handleClickChat = async () => {
    history.push("/inikaChat");
  };

  const handleClickTag = async () => {
    history.push("/inikaTagDemo");
  };

  const handleClickUpload = async () => {
    history.push("/uploadImages");
  };

  const handleClickView = async () => {
    history.push("/viewCatalog");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleUserRole();
      } else {
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    await signOut(auth);
    history.push("/login");
  };

  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "#F4D7BD",
        minHeight: "100vh",
        padding: "20px",
        width: "100%",
      }}
    >
      <div class="dropdown">
        <a
          className="NavDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoList"></div>
          <div class="twoDemoList"></div>
          <div class="threeDemoList"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/">
            Home
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
      <div className="listLogoContainer">
        <img src={inika_logo} alt="Centered Image" className="demoListLogo" />
      </div>

      <div
        classname="demoList"
        style={{
          width: "100%",
          marginTop: "0px",
          marginLeft: "0 auto",
          marginRight: "0 auto",
          // display: "none", // Delete This to revert back the changes.
        }}
      >
        <Row
          gutter={70}
          style={{
            width: "100%",
            marginLeft: "0 auto",
            marginRight: "0 auto",
            justifyContent: "center",
          }}
        >
          <Col
            span={8}
            style={
              userRole == "search" || userRole == "chat"
                ? {}
                : { display: "none" }
            }
          >
            <Card
              className="Search"
              bordered={false}
              onClick={handleClickSearch}
              hoverable
              style={
                userRole == "search" || userRole == "chat"
                  ? { height: "330px", width: "100%" }
                  : { height: "330px", width: "100%", display: "none" }
              }
            >
              <p className="SearchText">InikaSearch</p>
            </Card>
          </Col>
          <Col
            span={8}
            style={
              userRole == "tag" || userRole == "search" || userRole == "chat"
                ? {}
                : { display: "none" }
            }
          >
            <Card
              className="Tag"
              bordered={false}
              onClick={handleClickTag}
              hoverable
              style={
                userRole == "tag" || userRole == "search" || userRole == "chat"
                  ? { height: "330px", width: "100%" }
                  : { height: "330px", width: "100%", display: "none" }
              }
            >
              <p className="TagText">InikaTag</p>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              className="Chat"
              bordered={false}
              onClick={handleClickChat}
              hoverable
              style={
                userRole == "chat"
                  ? { height: "330px", width: "100%" }
                  : { height: "330px", width: "100%", display: "none" }
              }
            >
              <p className="ChatText">InikaChat</p>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        classname="uploadList"
        style={{
          width: "90%",
          marginTop: "60px",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <Row
          gutter={70}
          style={{ width: "100%", marginLeft: "0 auto", marginRight: "0 auto" }}
        >
          <Col span={12}>
            <Card
              className="uploadCatalog"
              bordered={false}
              onClick={handleClickUpload}
              hoverable
              style={{ height: "60px", width: "100%" }}
            >
              <p className="uploadCatalogText">Upload Catalog</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className="viewCatalog"
              bordered={false}
              onClick={handleClickView}
              hoverable
              style={{ height: "60px", width: "100%" }}
            >
              <div className="CatalogTextClass">
                <p className="viewCatalogText">View Catalog</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
