import React, { useState } from "react";
import { Card } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth2 } from "../../firebase";
import "./uploadTagImages.css";
import inika_logo from "../../Inika_logo.png";
import Swal from "sweetalert2";

const { v4: uuidv4 } = require("uuid");

export const UploadImagesTag = () => {
  const history = useHistory();
  const [files, setFiles] = useState(null);
  const BASE64_SIZE_MB = 10.67; // Approximate base64 size for 8MB binary data
  const MAX_BATCHES = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadCSV, setUploadCSV] = useState(false);

  // --------------------- Helper Functions ------------------------- //
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64Data = fileReader.result.match(/base64,(.*)$/)[1]; // Extract base64 part
        resolve(base64Data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  function splitBase64IntoChunks(base64Str, chunkSizeMB) {
    const chunkSize = chunkSizeMB * 1024 * 1024; // Convert MB to bytes
    const numChunks = Math.ceil(base64Str.length / chunkSize);
    const chunks = [];

    for (let i = 0; i < numChunks; i++) {
      const chunk = base64Str.slice(i * chunkSize, (i + 1) * chunkSize);
      chunks.push(chunk);
    }

    return chunks;
  }

  const handleFileChange = (event) => {
    setFiles(event.target.files); // Update state with selected files
  };

  // --------------------- Helper Functions ------------------------- //

  // --------------------- Upload Button Event Handling ------------------------- //

  const handleClick = async () => {
    setIsLoading(true);
    if (!files || files.length === 0) {
      alert("Please select one or more files first.");
      return;
    }

    const numberOfFiles = files.length;
    let uploadedFilesCount = 0;

    // Function to update the progress bar
    let myImages = [];
    let myIDs = [];
    let fileNames = [];
    let base64files = [];
    let contentList = [];

    for (const file of files) {
      let base64data = await convertBase64(file);
      fileNames.push(file.name);
      base64files.push(base64data);
      const uuid = uuidv4();
      myIDs.push(uuid);
      contentList.push(file.type);
    }

    try {
      const response = await axios.post(
        "https://us-central1-shopify-7a472.cloudfunctions.net/image_upload",
        {
          images: base64files,
          filenames: fileNames,
          contentType: contentList,
          user: auth2.currentUser.uid,
          ids: myIDs,
        }
      );
      // Update progress after each file is uploaded
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Image upload complete!",
      });
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
    setIsLoading(false);
  };

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/login");
    }
  });

  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/login");
  };

  return (
    <div className="uploadBG" style={{ backgroundColor: "#F4D7BD" }}>
      <input
        type="file"
        multiple
        hidden
        class="upload-file-input"
        id="uploadInput"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <div class="dropdown" className="NavUpload">
        <a
          className="uploadDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListUpload"></div>
          <div class="twoDemoListUpload"></div>
          <div class="threeDemoListUpload"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      <div></div>

      <div className="uploadImageContainer">
        <img src={inika_logo} alt="Centered Image" className="uploadImage" />
      </div>
      <div class="button-container">
        <label class="fakeButton" for="uploadInput">
          Choose Files
        </label>
        <button class="upload-upload-button" onClick={handleClick}>
          Upload Images
        </button>
      </div>
      <div style={{ marginTop: "250px" }}>
        {isLoading ? <div className="loading-spinner"></div> : <div></div>}
      </div>
    </div>
  );
};
