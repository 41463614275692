import React, { useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { auth2 } from "../../firebase";
import "./FileUploadCSV.css";
import { useHistory } from "react-router-dom";
import inika_logo from "../../Inika_logo.png";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Swal from "sweetalert2";

export const FileUploadCSV = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };
  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false,
  });

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!file) {
      alert("Please provide both a file and a user.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user", auth2.currentUser.uid);

    try {
      const response = await axios.post(
        "https://us-central1-shopify-7a472.cloudfunctions.net/upload_from_csv",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Image upload complete!",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="inikaTagBG">
      <div class="dropdown" className="NavTag">
        <a
          className="TagDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListTag"></div>
          <div class="twoDemoListTag"></div>
          <div class="threeDemoListTag"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      <div className="TagImageContainer">
        <img src={inika_logo} alt="Centered Image" className="TagImage" />
      </div>
      <div className="upload-container">
        <form onSubmit={handleSubmit}>
          <div className="dropzone-container" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag and drop a CSV file here, or click to select one</p>
            )}
          </div>
          <button type="submit" className="submit-button">
            Upload
          </button>
        </form>
        <div style={{}}>
          {isLoading ? <div className="loading-spinner"></div> : <div></div>}
        </div>
      </div>
    </div>
  );
};
