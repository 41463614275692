import React from 'react'
import avatarImage from './user_icon_new.png'
import { left } from '@popperjs/core'

const BotIcon = () => {
  return (
    <div
      style={{
        width: 8,
        height: 8,
        minWidth: 8,
        minHeight: 8,
        backgroundColor: 'transparent',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        font: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold'
      }}
    >
      <img
        alt='Bot'
        src={avatarImage}
        style={{
          paddingTop: '30px',
          maxWidth: '50px',
          maxHeight: '50px',
          width: 'auto',
          height: 'auto'
        }}
      />
    </div>
  )
}

const CustomUserMessage = props => {
  return (
    <div
      style={{
        minHeight: 23,
        paddingLeft: 18,
        paddingRight: 29,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
        // backgroundColor: "#F0F4FA",
      }}
    >
      <div
        style={{
          alignSelf: 'flex-start',
          marginTop: 5
        }}
      >
        {/* <span style={{
            color: "#000000",
          }}><h style = {{fontFamily: "'Oswald', sans-serif",
          fontSize:  "14px" }}>You:</h></span> */}

        <BotIcon />
      </div>
      <div
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 25,
          paddingTop: 12,
          width: '200px',
          paddingBottom: 12,
          marginTop: '10px',
          marginBot: '10px',
          border: '1px solid #ccc', // Add border
          borderRadius: 5, // Optional: Add border radius for rounded corners
          backgroundColor: '#ccccc1', // Set background color to white
          marginBottom: '2%',
          textAlign: left
        }}
      >
        <span
          style={{
            color: 'black',
            fontSize: '11px',
            fontWeight: 350,
            padding: '5px'
          }}
        >
          {props.message}
        </span>
        {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
//         <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
//         <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
//         <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
//         </DropdownButton>  */}
      </div>
    </div>
    // <div></div>
  )
}

export default CustomUserMessage
