import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import Collapsible from "react-collapsible";
import "./ImagePage.css"; // Ensure you have the corresponding CSS file
import { useHistory } from "react-router-dom";

const ImagePage = () => {
  const { index } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageUrl = queryParams.get("url");

  const [imageDescription, setImageDescription] = useState(null);
  const [imageTags, setImageTags] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await auth.authStateReady();
      const response = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/get_description_db",
        {
          user: auth.currentUser.uid,
          url: imageUrl,
        }
      );
      setImageDescription(response.data.Description);
      setImageTags(response.data.Tags);
    };

    fetchData();
  }, [index]);

  const handleBack = async () => {
    window.location.href = `/viewCatalog`;
  };

  return (
    <div className="image-page-container">
      <div className="back-arrow-container" onClick={handleBack}>
        <div className="back-arrow">&#8592;</div>
        <div className="back-text">Go back to catalog</div>
      </div>
      <div className="page-image-container">
        <img
          src={imageUrl}
          alt={`Image ${index}`}
          className="responsive-image"
        />
      </div>
      <div className="details-container">
        <h1 className="custom-h1">
          {/* {"You Image Title Here" || `Image ${index}`} */}
        </h1>
        <p>
          <span className="back-button" onClick={handleBack}>
            View Catalog&nbsp;
          </span>
          / Image
        </p>
        <Collapsible
          trigger={
            <div className="collapsible-trigger">
              <h2>Description</h2> <span className="arrow">▼</span>
            </div>
          }
        >
          <p>{imageDescription}</p>
        </Collapsible>
        <Collapsible
          trigger={
            <div className="collapsible-trigger">
              <h2>Tags</h2> <span className="arrow">▼</span>
            </div>
          }
        >
          {/* <ul>
            {imageData.tags.map((tag, idx) => (
              <li key={idx}>{tag}</li>
            ))}
          </ul> */}
          <p>{imageTags}</p>
        </Collapsible>
      </div>
    </div>
  );
};

export default ImagePage;
