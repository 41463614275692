import React, { useState, useContext } from "react";
import "./Chatbot.css";

// Provider context import
import { ChatbotContext } from "./ChatbotProvider";

const TriggerButton = ({ imgSrc }) => {
  const { changeBotVisibility, chatbotStylesConfig } =
    useContext(ChatbotContext);

  const { imageUrl } = chatbotStylesConfig.triggerButton;

  return (
    <div>
      <button
        className="trigger-button"
        onClick={changeBotVisibility}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></button>
    </div>
  );
};

export default TriggerButton;
