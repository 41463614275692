import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import './inikaTag.css'
import inika_logo from '../Inika_logo.png'
import upload_icon from '../cloud-computing.png'
import axios from 'axios'
import { doc } from 'firebase/firestore'
import { Downloading } from '@mui/icons-material'
import { ViewTagCatalog } from './viewTagCatalog.jsx'
export const InikaTag = () => {
  const [taggedImages, setTaggedImages] = useState([])

  const history = useHistory()

  // Check if they are Autheticated

  onAuthStateChanged(auth, user => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push('/login')
    }
  })

  // Logout Function
  const logout = async () => {
    await signOut(auth)
    console.log('signed out')
    history.push('/login')
  }
  return (
    <div className='inikaTagBG'>
      {/* Navigation Bar Code  */}
      <div class='dropdown' className='NavTag'>
        <a
          className='TagDemoList'
          href='#'
          role='button'
          id='dropdownMenuLinkDemo'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <div class='oneDemoListTag'></div>
          <div class='twoDemoListTag'></div>
          <div class='threeDemoListTag'></div>
        </a>
        <div class='dropdown-menu' aria-labelledby='dropdownMenuLinkDemo'>
          <a class='dropdown-item1' href='/demo'>
            Explore
          </a>
          <br></br>
          <a class='dropdown-item2' onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      {/* Logo Code  */}

      <div className='TagImageContainer'>
        <img src={inika_logo} alt='Centered Image' className='TagImage' />
      </div>

      <div>
        {' '}
        <ViewTagCatalog taggedImages={taggedImages} />
      </div>
    </div>
  )
}
