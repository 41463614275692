//import React from "react";
import { createCustomMessage } from "react-chatbot-kit";

// Custom Messages
import CustomBotMessage from "./CustomBotMessageCHAT";
import CustomUserMessage from "./CustomUserMessageCHAT";
import CustomFirstMessage from "./CustomFirstMessageCHAT";
import { createChatBotMessage } from "react-chatbot-kit";

// Custom Widgets
import LearningOptions from "./components/LearningOptions/LearningOptions";
import LinkList from "./components/LinkList/LinkList";

import ChatHelp from "./components/MainChoice/Chat_Help";
import Options from "./components/Options";

import TrackOrder from "./components/TrackOrder";
import MobileNumber from "./components/TrackOrder/MobileNumber";
import OrderID from "./components/TrackOrder/OrderID";

import ReturnOrder from "./components/ReturnOrder/ReturnOrder";

import CancelOrder from "./components/CancelOrder/CancelOrder";

import RefundStatus from "./components/RefundStatus/RefundStatus";

import FAQ from "./components/FAQ/FAQ";
// Sub FAQ
import ShippingDelivery from "./components/FAQ/ShippingDelivery/ShippingDelivery";
import Payments from "./components/FAQ/Payments/Payments";
import Returns from "./components/FAQ/Returns/Returns";
import Refunds from "./components/FAQ/Refunds/Refunds";

const config = {
  botName: "InikaChat",
  initialMessages: [
    /*createChatBotMessage("Hi, I'm here to help. What do you want to learn?",{
            widget: "learningOptions",
        }),*/
    // createCustomMessage("first message", "customFirstMessage"),
    createChatBotMessage(`Hi I'm Inika, your virtual personal assistant!`, {
      widget: "MainMenu",
    }),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#FFFFFF",
    },
  },
  customMessages: {
    custom: (props) => <CustomBotMessage {...props} />,
    customFirstMessage: (props) => <CustomFirstMessage {...props} />,
  },
  customComponents: {
    userChatMessage: CustomUserMessage,
    userAvatar: () => {}, // CustomUserMessage renders the avatar by itself
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} />,
    },
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "TrackOrder",
      widgetFunc: (props) => <TrackOrder {...props} />,
    },
    {
      widgetName: "MobileNumber",
      widgetFunc: (props) => <MobileNumber {...props} />,
    },
    {
      widgetName: "OrderID",
      widgetFunc: (props) => <OrderID {...props} />,
    },

    {
      widgetName: "ReturnOrder",
      widgetFunc: (props) => <ReturnOrder {...props} />,
    },

    {
      widgetName: "CancelOrder",
      widgetFunc: (props) => <CancelOrder {...props} />,
    },

    {
      widgetName: "RefundStatus",
      widgetFunc: (props) => <RefundStatus {...props} />,
    },

    {
      widgetName: "FAQ",
      widgetFunc: (props) => <FAQ {...props} />,
    },
    {
      widgetName: "ShippingDelivery",
      widgetFunc: (props) => <ShippingDelivery {...props} />,
    },
    {
      widgetName: "Payments",
      widgetFunc: (props) => <Payments {...props} />,
    },
    {
      widgetName: "Returns",
      widgetFunc: (props) => <Returns {...props} />,
    },
    {
      widgetName: "Refunds",
      widgetFunc: (props) => <Refunds {...props} />,
    },

    {
      widgetName: "MainMenu",
      widgetFunc: (props) => <ChatHelp {...props} />,
    },

    {
      widgetName: "javascriptLinks",
      widgetFunc: (props) => <LinkList {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            url: "https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/basic-javascript/",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide",
            id: 2,
          },
          {
            text: "Frontend Masters",
            url: "https://frontendmasters.com",
            id: 3,
          },
        ],
      },
    },
  ],
};

export default config;
