import React, { useState } from "react";
import { Card } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";
import "./uploadimages.css";
import inika_logo from "../Inika_logo.png";
import Swal from "sweetalert2";

const { v4: uuidv4 } = require("uuid");

<meta
  http-equiv="Content-Security-Policy"
  content="upgrade-insecure-requests"
></meta>;

export const UploadImages = () => {
  const history = useHistory();
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // --------------------- Helper Functions ------------------------- //
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64Data = fileReader.result.match(/base64,(.*)$/)[1]; // Extract base64 part
        resolve(base64Data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function splitBase64IntoChunks(base64Str, chunkSizeMB) {
    const chunkSize = chunkSizeMB * 1024 * 1024; // Convert MB to bytes
    const numChunks = Math.ceil(base64Str.length / chunkSize);
    const chunks = [];

    for (let i = 0; i < numChunks; i++) {
      const chunk = base64Str.slice(i * chunkSize, (i + 1) * chunkSize);
      chunks.push(chunk);
    }

    return chunks;
  }

  const handleFileChange = (event) => {
    setFiles(event.target.files); // Update state with selected files
  };

  // --------------------- Helper Functions ------------------------- //

  async function batchAndSend(
    base64files,
    fileNames,
    contentList,
    myIDs,
    auth
  ) {
    const MAX_BATCH_SIZE = 8 * 1024 * 1024; // 8MB
    const requests = [];
    let currentBatchSize = 0;
    let currentBatch = {
      images: [],
      filenames: [],
      contentType: [],
      user: auth.currentUser.uid,
      ids: [],
    };

    for (let i = 0; i < base64files.length; i++) {
      const fileSize = (base64files[i].length * 3) / 4;

      // Check if individual file exceeds the limit
      if (fileSize > MAX_BATCH_SIZE) {
        console.error(
          `File at index ${i} exceeds the 8MB limit and will not be sent.`
        );
        continue; // Skip this file
      }

      if (
        currentBatchSize + fileSize > MAX_BATCH_SIZE &&
        currentBatch.images.length > 0
      ) {
        // Push current batch to requests and reset
        requests.push(sendBatch(currentBatch));
        currentBatch = {
          images: [],
          filenames: [],
          contentType: [],
          user: auth.currentUser.uid,
          ids: [],
        };
        currentBatchSize = 0;
      }

      // Add current file to the batch
      currentBatch.images.push(base64files[i]);
      currentBatch.filenames.push(fileNames[i]);
      currentBatch.contentType.push(contentList[i]);
      currentBatch.ids.push(myIDs[i]);
      currentBatchSize += fileSize;
    }

    // Push the last batch if it has files
    if (currentBatch.images.length > 0) {
      requests.push(sendBatch(currentBatch));
    }

    // Execute all requests concurrently
    await Promise.all(requests);
  }

  function sendBatch(batch) {
    return axios.post(
      "https://us-central1-inika-webpage.cloudfunctions.net/image-upload-multithread",
      batch
    );
  }

  // --------------------- Upload Button Event Handling ------------------------- //

  const handleClick = async () => {
    if (!files || files.length === 0) {
      alert("Please select one or more files first.");
      return;
    }

    const numberOfFiles = files.length;
    let uploadedFilesCount = 0;

    // Function to update the progress bar
    let myImages = [];
    let myIDs = [];
    let fileNames = [];
    let base64files = [];
    let contentList = [];

    for (const file of files) {
      let base64data = await convertBase64(file);
      fileNames.push(file.name);
      base64files.push(base64data);
      const uuid = uuidv4();
      myIDs.push(uuid);
      contentList.push(file.type);
    }

    try {
      setIsLoading(true);
      await batchAndSend(
        base64files,
        fileNames,
        contentList,
        myIDs,
        auth
      ).catch(console.error);

      // Update progress after each file is uploaded
    } catch (error) {
      console.error("Error uploading file: ", error);
    }

    /* Un-comment the portion below to add embeddings form the server again  */

    await axios.post("https://api.inika.app/EmbedImage", {
      user: auth.currentUser.uid,
      id_list: myIDs,
      base_64_data: base64files,
    });

    setIsLoading(false);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Image upload complete!",
    });
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/login");
    }
  });

  const logout = async () => {
    await signOut(auth);
    console.log("signed out");
    history.push("/login");
  };

  return (
    <div className="uploadBG" style={{ backgroundColor: "#F4D7BD" }}>
      <input
        type="file"
        multiple
        hidden
        class="upload-file-input"
        id="uploadInput"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <div class="dropdown" className="NavUpload">
        <a
          className="uploadDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListUpload"></div>
          <div class="twoDemoListUpload"></div>
          <div class="threeDemoListUpload"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/demo">
            Explore
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      <div className="uploadImageContainer">
        <img src={inika_logo} alt="Centered Image" className="uploadImage" />
      </div>
      <div class="button-container">
        <label class="fakeButton" for="uploadInput">
          Choose Files
        </label>
        <button class="upload-upload-button" onClick={handleClick}>
          Upload Images
        </button>
      </div>
      <div style={{ marginTop: "250px" }}>
        {isLoading ? <div className="loading-spinner"></div> : <div></div>}
      </div>
    </div>
  );
};
