import React from "react";

import "./Payments.css";

const Payments = (props) => {
  const options = [
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrackFAQ, 
    id: 1
    },
    { text: "Main Menu", 
    handler: props.actionProvider.handleBackTrackMain, 
    id: 2
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Payments;
