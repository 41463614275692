import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { ChatbotContext } from "./ChatbotProvider"; // Provider context import

const BotIcon = ({ imgSrc }) => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "Roboto",
        color: "black",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      <img
        alt=""
        src={imgSrc}
        style={{
          maxWidth: "43px",
          maxHeight: "43px",
          borderRadius: 50,
          width: "auto",
          height: "auto",
          border: "1px solid black",
          // overflow: "hidden",
        }}
      />
    </div>
  );
};

const TextMessageContainer = ({ message }) => {
  const { chatbotStylesConfig } = useContext(ChatbotContext);
  const { botMessageIcon, messageColor, textColor } =
    chatbotStylesConfig.botMessage;
  //   const [displayResponse, setDisplayResponse] = useState("");
  //   const [completedTyping, setCompletedTyping] = useState(false);
  //   const {
  //     messages,
  //   } = useContext(ChatbotContext);

  //   const { botMessageIcon } = chatbotStylesConfig.botMessage;

  //   useEffect(() => {
  //     if (!messages?.length) {
  //       return;
  //     }

  //     setCompletedTyping(false);

  //     let i = 0;
  //     const stringResponse = message;

  //     const intervalId = setInterval(() => {
  //       setDisplayResponse(stringResponse.slice(0, i));

  //       i++;

  //       if (i > stringResponse.length) {
  //         clearInterval(intervalId);
  //         setCompletedTyping(true);
  //       }
  //     }, 20);

  //     return () => clearInterval(intervalId);
  //   }, [message]);
  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 0,
        paddingRight: 29,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: "0px 0px",
        borderStyle: "solid",
        borderColor: "rgba(164, 198, 229, 0.5)",
      }}
    >
      {/* <UserIcon imgSrc={botMessageIcon}></UserIcon> */}
      <BotIcon imgSrc={botMessageIcon}></BotIcon>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "20px",
          border: "2px solid #ddd",
          padding: "20px",
          backgroundColor: messageColor,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "max-content",
          marginLeft: "2%",
        }}
      >
        <span style={{ fontSize: "12px", color: textColor }}>{message}</span>
      </div>
    </div>
  );
};

export default TextMessageContainer;
