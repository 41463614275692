import React from "react";

import "./Returns.css";

const Returns = (props) => {
  const options = [
    {
      text: "How do I cancel an order?",
      handler: props.actionProvider.handleCancelOrderQuery,
      id: 1,
    },
    { text: "How do I return an order?", 
    handler: props.actionProvider.handleReturnOrderQuery, 
    id: 2 
    },
    { text: "What is your return policy", 
    handler: props.actionProvider.handleReturnPolicy, 
    id: 3 
    },
    { text: "How do I return an order with promos or discounts?", 
    handler: props.actionProvider.handlePromoQuery, 
    id: 4 
    },
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrackFAQ, 
    id: 5 
    },
    { text: "Main Menu", 
    handler: props.actionProvider.handleBackTrackMain, 
    id: 6
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Returns;
