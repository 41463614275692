import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";

export const Login = () => {
  // If they are already logged in 0 redirect to demo list page

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleSignIn = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        history.push("/demo");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Incorrect Username or Password!",
          text: "Please enter a valid username and password combination.",
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <div
      className="Login Default"
      style={{
        backgroundColor: "#F4D7BD",
        opacity: 1,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="signin-container">
        <div
          className="signin-form"
          style={{ textAlign: "center", maxWidth: "400px" }}
        >
          <h2>Login</h2>
          <form onSubmit={handleSignIn} style={{ margin: "auto" }}>
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label htmlFor="email" style={{ marginRight: "10px" }}>
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "100%",
                }}
              />
            </div>
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label htmlFor="password" style={{ marginRight: "10px" }}>
                Password
              </label>
              <p
                style={{
                  marginTop: "20px",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              >
                {" "}
                <Link
                  to="/reset"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Forgot Password?
                </Link>
              </p>{" "}
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "100%",
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#000000",
                color: "#fff",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Sign In
            </button>
          </form>
          <p
            style={{
              marginTop: "20%",
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Interested in the custom demo behind this page?
          </p>{" "}
          {/* Link to the home page */}
          {/* <p style={{ marginTop: '20px', fontSize: '14px' }}> Don't have an account? <a href="./#contact" style={{ color: '#007bff', textDecoration: 'none' }}>Join Our Waitlist</a></p> Link to the home page */}
        </div>
        <div>
          {" "}
          <a
            style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
            href="https://calendly.com/ykothari-4jb8/inikasuite-demo"
            className="btn btn-custom btn-lg page-scroll"
          >
            Sign up for a demo
          </a>
        </div>
      </div>
    </div>
  );
};
