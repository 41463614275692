// Library Imports
import React, { useState, useContext } from "react";

// Style Imports
import "./Chatbot.css";

// Component Imports
import TriggerButton from "./TriggerButton";
import ChatbotWindow from "./ChatbotWindow";
import { ChatbotContext } from "./ChatbotProvider"; // Provider context import

const ChatbotContainer = () => {
  const { toggleChatbot } = useContext(ChatbotContext);

  return <div>{toggleChatbot ? <ChatbotWindow /> : <TriggerButton />}</div>;
};

export default ChatbotContainer;
