import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth2 } from "../../firebase.js";
import "../inikaTag.css";
import inika_logo from "../../Inika_logo.png";
import { ViewTagCatalog } from "./viewTaggingCatalog.jsx";
export const InikaTag_Live = () => {
  const [taggedImages, setTaggedImages] = useState([]);

  const history = useHistory();

  // Check if they are Autheticated

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  // Logout Function
  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };
  return (
    <div className="inikaTagBG">
      {/* Navigation Bar Code  */}
      <div class="dropdown" className="NavTag">
        <a
          className="TagDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListTag"></div>
          <div class="twoDemoListTag"></div>
          <div class="threeDemoListTag"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      {/* Logo Code  */}

      <div className="TagImageContainer">
        <img src={inika_logo} alt="Centered Image" className="TagImage" />
      </div>

      <div>
        {" "}
        <ViewTagCatalog taggedImages={taggedImages} />
      </div>
    </div>
  );
};
