// Library Imports
import React, { useState, useContext } from "react";
// Style Imports
import "./Chatbot.css";
import { ChatbotContext } from "./ChatbotProvider";
import TextMessageContainer from "./TextBotMessage";
import ImageMessageContainer from "./ImageMessageContainer";
import { GenderButton } from "./GenderButton";
import { ResetButton } from "./ResetButton";
import TextUserMessage from "./TextUserMessage";

const MessageView = () => {
  const [input, setInput] = useState("");
  const { messages, outfitList, gender } = useContext(ChatbotContext);
  return (
    <div className="chatbot-container-children chatbot-messages">
      {messages.map(
        (message, index) =>
          message !== undefined &&
          (!message.user ? (
            <div key={`${message.text}-${index}`}>
              {" "}
              <TextMessageContainer key={`${index}`} message={message.text} />
              {message.urls && (
                <ImageMessageContainer
                  key={`${message.urls}-${index}`}
                  imgSrcs={[message.urls, message.productLinks]}
                />
              )}
            </div>
          ) : (
            <div key={index}>
              <TextUserMessage message={message.text} />
            </div>
          ))
      )}
      {!gender && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "2vw",
          }}
        >
          <GenderButton gender="male" />
          <GenderButton gender="female" />
        </div>
      )}
      {outfitList.length > 0 && <ResetButton />}
    </div>
  );
};

export default MessageView;
