import React from "react";

import "./ShippingDelivery.css";

const ShippingDelivery = (props) => {
  const options = [
    {
      text: "What is the Delivery Time?",
      handler: props.actionProvider.handleDeliveryTime,
      id: 1,
    },
    { text: "When is Free Shipping Available", 
    handler: props.actionProvider.handleShippingAvailable, 
    id: 2 
    },
    { text: "Do you Ship Internationally?", 
    handler: props.actionProvider.handleShippingInternational, 
    id: 3 
    },
    { text: "I was unable to receive my order?", 
    handler: props.actionProvider.handleReceiveOrder, 
    id: 4 
    },
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrackFAQ, 
    id: 5 
    },
    { text: "Main Menu", 
    handler: props.actionProvider.handleBackTrackMain, 
    id: 6
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default ShippingDelivery;
