import React from 'react'

import example_1 from './examples-1.png'
import example_2 from './examples-2.png'
import example_3 from './examples-3.png'

const InstrutionsViewer = () => {
  return (
    <div>
      <div
        style={{
          background: '#E9D8C9',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img style={{ width: '80%' }} src={example_1} alt='...' />
      </div>
      <div
        style={{
          background: '#E9D8C9',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img style={{ width: '80%' }} src={example_2} alt='...' />
      </div>
      <div
        style={{
          background: '#E9D8C9',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img style={{ width: '80%' }} src={example_3} alt='...' />
      </div>
    </div>
  )
}
export default InstrutionsViewer
