import React from 'react'

import './inikaChat.css'

import InputBase from '@mui/material/InputBase'

import { styled } from '@mui/material/styles'
import inika_logo from '../Inika_logo.png'
import { auth } from '../firebase'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}))

const UserIcon = () => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: 'transparent',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        font: 'Roboto',
        color: 'black',
        fontSize: '14px',
        fontWeight: 'bold'
      }}
    >
      {/* <img alt="" src={inika_logo} style={{
          maxWidth: '40px',
          maxHeight: '40px',
          width: 'auto', 
          height: 'auto' 
        }}/> */}
    </div>
  )
}

function processPaths (paths, prefix, substring) {
  return paths.map(path => {
    const index = path.indexOf(substring)
    if (index !== -1) {
      const substringIndex = index + substring.length
      return prefix + path.substring(substringIndex)
    }
    return path
  })
}

function removeLastCharacters (strings, count) {
  return strings.map(str => str.substring(0, str.length - count))
}

const CustomBotMessage = props => {
  // const isBlink = props.payload.first;
  const { message, isBotMessage } = props.payload
  let message_result = message.replaceAll("'", '')

  // Formatting Image strings
  message_result = message_result.replaceAll('[', '')
  message_result = message_result.replaceAll(']', '')
  message_result = message_result.replaceAll('\\\\', '/')
  message_result = message_result.split(',')

  //  --------

  //let first_item = 'closet/flanel.jpg'
  let second_item = String(message_result[0])
  //let imagesPool = [
  //{ src: second_item},
  //{ src: 'closet/flanel.jpg'},
  //{ src: 'closet/flanel.jpg'}
  //]
  let images = []
  for (let i = 0; i < message_result.length; i++) {
    let image = String(message_result[i])
    let index = image.indexOf(auth.currentUser.uid)
    let result = image.substring(index)
    result = 'https://storage.googleapis.com/inika-webpage.appspot.com/'.concat(
      result
    )
    images.push({ src: result })
  }
  // const tool_usage = message.split('@')[1]
  // const list_message = []
  // if (isBlink === "no"){
  //   const list = message.split('@@')[1]
  //   const list_array = list.split('$$')
  //   console.log(list)
  //   list_array.pop()

  //   for (let i = 0; i < list_array.length; i++) {
  //   // note: we are adding a key prop here to allow react to uniquely identify each
  //   // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
  //   list_message.push(<li key={i} >{list_array[i]}</li >);
  //   }
  // // console.log(list_message)
  // }

  // const [displayResponse, setDisplayResponse] = useState("");
  //   const [completedTyping, setCompletedTyping] = useState(false);

  //   useEffect(() => {
  //       setCompletedTyping(false);

  //       let i = 0;
  //       const stringResponse = message_result;

  //       const intervalId = setInterval(() => {
  //         setDisplayResponse(stringResponse.slice(0, i));

  //         i++;

  //         if (i > stringResponse.length) {
  //           clearInterval(intervalId);
  //           setCompletedTyping(true);
  //         }
  //       }, 20);

  //       return () => clearInterval(intervalId);
  //     }, [message_result]);

  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 0,
        paddingRight: 29,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderWidth: '0px 0px',
        borderStyle: 'solid',
        borderColor: 'rgba(164, 198, 229, 0.5)'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 20,
          paddingTop: 35,
          paddingBottom: 30
        }}
      >
        <div>
          <span
            style={{
              color: '#333333',

              fontSize: '24px',
              fontWeight: 200
            }}
          >
            {/* {message_result} */}
            {images.map((imgSrc, index) => (
              <img
                src={imgSrc.src}
                style={{
                  height: '140px',
                  width: '100px',
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  paddingBottom: '10px',
                  paddingTop: '10px'
                }}
                key={index}
                alt='Make sure to include a alt tag, because react might throw an error at build'
              />
            ))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CustomBotMessage
