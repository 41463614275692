import { useHistory } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";
import React, { useState, useEffect } from "react";

import "./viewCatalog.css";

export const ViewTagCatalog = (props) => {
  const history = useHistory();
  var [imageData, setImageData] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  var flipped = false;
  let uid = auth.uid;

  // access the data passed from ParentPage (inikaTag) using props
  // props are used to send data to component just like a prameter to fun
  const { taggedImages } = props;

  if (taggedImages.length > 0) {
    imageData = taggedImages;
  }

  // logic to flip the image
  const [flippedMap, setFlippedMap] = useState(new Map()); // Initialize hashmap to store flipped status

  // Function to handle flipping an image by index
  const handleFlip = (index) => {
    const updatedFlippedMap = new Map(flippedMap); // Create a copy of flippedMap
    // Toggle the flip status for the given index
    updatedFlippedMap.set(index, !updatedFlippedMap.get(index));
    // Update state with the new flippedMap
    setFlippedMap(updatedFlippedMap);
  };

  const flipAllTagged = () => {
    const updatedFlippedMap = new Map(flippedMap); // Create a copy of flippedMap
    imageData
      .filter((item) => item.tags)
      .forEach((parsedUrl) => {
        // Toggle the flip status for the given index
        const index = imageURLs.findIndex((url) => url === parsedUrl.url);
        updatedFlippedMap.set(index, !updatedFlippedMap.get(index));
        // Update state with the new flippedMap
      });

    setFlippedMap(updatedFlippedMap);
    flipped = !flipped;
    // console.log(flipped)
  };

  const [selectedImageURLs, setSelectedImageURLs] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);

  // Function to handle image selection
  const handleImageSelection = (url) => {
    if (selectedImageURLs.includes(url)) {
      // If image URL is already selected, deselect it
      setSelectedImageURLs(
        selectedImageURLs.filter((selectedURL) => selectedURL !== url)
      );
    } else {
      // If image URL is not selected, add it to selected URLs
      setSelectedImageURLs([...selectedImageURLs, url]);
    }
  };

  function getElementByImageUrl(imageUrlToFind) {
    return taggedImages.find((item) => item.url === imageUrlToFind);
  }

  function getElementByImageUrlTag(imageUrlToFind) {
    return imageData.find((item) => item.url === imageUrlToFind);
  }

  const handleTagImageClick = () => {
    // Navigate to Tag Image page and pass selectedImages as state
    history.push({
      pathname: "/inikaTagActions",
      state: { selectedImageURLs, imageData },
    });
  };

  useEffect(() => {
    const handleFileChange = async () => {
      const authState = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          // ...
        } else {
          history.push("/login");
          // User is signed out
          // ...
        }
      });
      try {
        await auth.authStateReady();
        const response = await axios.post(
          "https://us-central1-inika-webpage.cloudfunctions.net/url-fetch-tags",
          {
            user: auth.currentUser.uid,
          }
        );
        const imageURLs_fetched = response.data.url_list.map(
          (item) => item.url
        );

        setImageURLs(imageURLs_fetched);
        setImageData(response.data.url_list);

        // Update progress after each file is uploaded
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    };
    handleFileChange();
  }, []); // Empty dependency array to run once on mount

  const logout = async () => {
    await signOut(auth);
    history.push("/login");
  };

  const renderImageFlipCard = (url, id, index) => {
    const isFlipped = flippedMap.get(index);
    return (
      <div
        key={index}
        className="image-wrapper"
        style={{
          perspective: "1000px",
          width: "200px",
          height: "270px",
          position: "relative",
          display: "flex",
        }}
      >
        {/* Checkbox for image selection */}
        {showCheckboxes && (
          <input
            type="checkbox"
            checked={selectedImageURLs.includes(id)}
            onChange={() => handleImageSelection(id)}
            id={`checkbox-${index}`}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              zIndex: "10",
            }}
          />
        )}

        <div
          className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}
          style={{
            width: "100%",
            height: "100%",
            transformStyle: "preserve-3d",
            transition: "transform 0.5s",
          }}
          onClick={() => {
            if (showCheckboxes) {
              handleImageSelection(id);
            } else {
              handleFlip(index);
            }
          }}
        >
          <div className="flip-card-front">
            <img
              className="imageItem"
              src={url}
              alt={`Image ${index}`}
              style={{
                width: "200px",
                height: "270px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
              loading="lazy"
            />
          </div>
          <div className="flip-card-back ">
            <div
              className="tag-card unageItem"
              style={{
                width: "200px",
                height: "270px",
                marginRight: "20px",
                marginLeft: "20px",
                overflowY: "scroll",
              }}
            >
              {getElementByImageUrlTag(url) &&
                getElementByImageUrlTag(url).tags && (
                  <div>
                    {Object.entries(getElementByImageUrlTag(url).tags).map(
                      ([key, value], index) =>
                        // Check if the key is not 'imageUrl'
                        key !== "url" && (
                          <div
                            className="output-tag-list-item"
                            style={{ margin: "8px 16px" }}
                            key={index}
                          >
                            {key !== "editableTag" ? (
                              <span>
                                {key}: {value}
                              </span>
                            ) : (
                              <input
                                type="text"
                                defaultValue={value}
                                autoFocus // Focus on the input when it becomes editable
                              />
                            )}
                          </div>
                        )
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="inikaViewCatalogBG">
      <div
        className="buttonContainer-view"
        style={{
          position: "relative",
          marginLeft: "0",
          marginBottom: "16px",
          width: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          gap: "16px",
        }}
      >
        <button
          onClick={() => setShowCheckboxes(!showCheckboxes)}
          style={{ backgroundColor: "#C1AA96", opacity: "0.8", color: "#000" }}
        >
          {showCheckboxes ? "De-Select" : "Select"}
        </button>

        {showCheckboxes && selectedImageURLs.length > 0 && (
          <button
            onClick={handleTagImageClick}
            style={{
              backgroundColor: "#C1AA96",
              opacity: "0.8",
              color: "#000",
            }}
          >
            Tag Selected Images
          </button>
        )}
        {showCheckboxes && selectedImageURLs.length > 0 && (
          <button
            onClick={flipAllTagged}
            style={{
              backgroundColor: "#C1AA96",
              opacity: "0.8",
              color: "#000",
            }}
          >
            Flip all Tagged
          </button>
        )}
      </div>

      <div className="image-container">
        {imageData.map((data, index) =>
          renderImageFlipCard(data.url, data.id, index)
        )}
      </div>
    </div>
  );
};
