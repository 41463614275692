import { useHistory } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth2 } from "../../firebase";
import inika_logo from "../../Inika_logo.png";
import React, { useState, useEffect } from "react";
import "../viewCatalog.css";

export const ViewCatalogTag = () => {
  const history = useHistory();
  const [files, setFiles] = useState(null);
  const [overallProgress, setOverallProgress] = useState(0);
  const [imageURLs, setImageURLs] = useState([]);
  let uid = auth2.uid;

  const [selectedImageURLs, setSelectedImageURLs] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);

  // Function to handle image selection
  const handleImageSelection = (url) => {
    if (selectedImageURLs.includes(url)) {
      // If image URL is already selected, deselect it
      setSelectedImageURLs(
        selectedImageURLs.filter((selectedURL) => selectedURL !== url)
      );
    } else {
      // If image URL is not selected, add it to selected URLs
      setSelectedImageURLs([...selectedImageURLs, url]);
    }
  };

  const fetchImages = async (user) => {
    const response = await axios.post(
      "https://us-central1-inika-webpage.cloudfunctions.net/url-fetch",
      { user }
    );
    setImageURLs(response.data.imageURLs);
    return response.data;
  };

  const handleTagImageClick = () => {
    // Navigate to Tag Image page and pass selectedImages as state
    history.push({
      pathname: "/inikaTag",
      state: { selectedImageURLs },
    });
  };

  const handleDeleteSelectedImages = async () => {
    // Filter out the selected image URLs from the imageURLs array
    const updatedImageURLs = imageURLs.filter((url) =>
      selectedImageURLs.includes(url)
    );
    for (const file of updatedImageURLs) {
      const blo = file.slice(57); // If Something breaks - Look here first
      try {
        const response = await axios.post(
          "https://us-central1-shopify-7a472.cloudfunctions.net/deleteImages",
          {
            url: file,
            blob: blo,
            user: auth2.currentUser.uid,
          }
        );
      } catch (error) {
        console.error("Error Deleting file: ", error);
      }
    }

    // Update the imageURLs state with the filtered array
    // Clear the selected image URLs
    setSelectedImageURLs([]);
    window.location.reload();
  };

  useEffect(() => {
    const handleFileChange = async () => {
      const authState = onAuthStateChanged(auth2, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          // ...
        } else {
          console.log("No");
          history.push("/shopify-login");
          // User is signed out
          // ...
        }
      });
      try {
        await auth2.authStateReady();
        const response = await axios.post(
          "https://us-central1-shopify-7a472.cloudfunctions.net/url-fetch",
          {
            user: auth2.currentUser.uid,
          }
        );
        setImageURLs(response.data.imageURLs);
        // Update progress after each file is uploaded
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    };
    handleFileChange();
  }, []); // Empty dependency array to run once on mount

  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };

  return (
    <div className="inikaViewCatalogBG">
      <div class="dropdown" className="ViewCatalogChat">
        <a
          className="ViewCatalogDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListViewCatalog"></div>
          <div class="twoDemoListViewCatalog"></div>
          <div class="threeDemoListViewCatalog"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
      <div className="buttonContainer-view">
        <button
          onClick={() => setShowCheckboxes(!showCheckboxes)}
          style={{ backgroundColor: "#C1AA96", opacity: "0.8", color: "#000" }}
        >
          {showCheckboxes ? "De-Select" : "Select"}
        </button>

        {showCheckboxes && selectedImageURLs.length > 0 && (
          <button
            onClick={handleDeleteSelectedImages}
            style={{
              backgroundColor: "#C1AA96",
              opacity: "0.8",
              color: "#000",
            }}
          >
            Delete Selected Images
          </button>
        )}
      </div>

      {/* Logo Code  */}

      <div
        className="ViewCatalogImageContainer"
        style={{ justifyContent: "left" }}
      >
        <div className="ImageContainer-view">
          <img
            src={inika_logo}
            alt="Centered Image"
            className="ViewCatalogImage"
          />
        </div>
      </div>

      <div
        className="image-container"
        style={{ justifyContent: "left", marginLeft: "50px" }}
      >
        {imageURLs.map((url, index) => (
          <div key={index} className="image-wrapper">
            {/* Checkbox for image selection */}
            {showCheckboxes && (
              // Checkbox for image selection
              <input
                type="checkbox"
                checked={selectedImageURLs.includes(url)}
                onChange={() => handleImageSelection(url)}
                id={`checkbox-${index}`}
                style={{ width: "20px", height: "20px", marginRight: "5px" }} // Adjust dimensions and styling as needed
              />
            )}
            <img
              className="imageItem"
              src={url}
              alt={`Image ${index}`}
              style={{
                width: "200px",
                height: "270px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
              loading="lazy"
              onClick={() => handleImageSelection(url)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
