import React, { useEffect, useState } from "react";
import { Navigation } from "../navigationLogin";
import axios from "axios";
import { auth2 } from "../../firebase";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import "./InikaTagCredits.css";

export const ShopifyLanding = () => {
  const history = useHistory();
  function calcDollars(a) {
    var x = Math.round((a / 100) * 100) / 100;
    return x.toFixed(2);
  }

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };
  const [data, setData] = useState(null);
  const url =
    "https://us-central1-shopify-7a472.cloudfunctions.net/checCreditAmount";
  useEffect(() => {
    const getUserCredit = async () => {
      await auth2.authStateReady();
      axios
        .post(url, {
          user: auth2.currentUser.uid,
        })
        .then((response) => {
          setData(response.data.credits);
        });
    };
    getUserCredit();
  });

  const exportJSON = async () => {
    const response = await axios.post(
      "https://us-central1-shopify-7a472.cloudfunctions.net/exportToJson",
      {
        user: auth2.currentUser.uid,
      }
    );
    const tag_json = response.data;
    const download_file = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(tag_json)
    )}`;
    const link = document.createElement("a");
    link.href = download_file;
    link.download = "tags.json";
    link.click();
  };

  const exportCSV = async () => {
    const response = await axios.post(
      "https://us-central1-shopify-7a472.cloudfunctions.net/exportToCsv",
      {
        user: auth2.currentUser.uid,
      }
    );
    const tag_csv = response.data.csv;
    const download_file = `data:text/csv;chatset=utf-8,${encodeURIComponent(
      tag_csv
    )}`;
    const link = document.createElement("a");
    link.href = download_file;
    link.download = "tags.csv";
    link.click();
  };

  return (
    <div style={{ backgroundColor: "#F4D7BD", height: "100%" }}>
      <div class="dropdown" className="NavSearch">
        <a
          className="SearchDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListSearch"></div>
          <div class="twoDemoListSearch"></div>
          <div class="threeDemoListSearch"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        <ul style={{ verticalAlign: "middle" }}>
          <li>
            <span
              style={{ fontSize: "30px", color: "black", fontWeight: "bold" }}
            >
              {" "}
              {data == null ? (
                <div
                  style={{ alignItems: "center", backgroundColor: "black" }}
                  className="loading-spinner"
                ></div>
              ) : (
                <div>Credit balance: ${calcDollars(data)}</div>
              )}
            </span>
          </li>
          <li style={{ paddingTop: "50px" }}>
            <a href="/InikaTagLive" class="button" style={{}}>
              Tag Images
            </a>
            <a href="/payment" class="button" style={{}}>
              Buy Credits
            </a>
            <a href="/upload-tag" class="button" style={{}}>
              Upload Images
            </a>
            <br></br>
            <a class="button" onClick={exportJSON}>
              Export to JSON
            </a>
            <a class="button" onClick={exportCSV}>
              Export to CSV
            </a>
            <a href="/inikaTagCatalog" class="button" style={{}}>
              View Catalog
            </a>
            <a href="/inikaTagCSV" class="button" style={{}}>
              Upload CSV
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
