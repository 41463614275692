// Library Imports
import React, { useState, useContext } from "react";
// Style Imports
import "./Chatbot.css";
// Component Imports
import ChatbotHeader from "./ChatbotHeader";
import MessageView from "./MessageView";
import InputContainer from "./InputContainer";
// Provider context import
import { ChatbotContext } from "./ChatbotProvider";

const ChatbotWindow = () => {
  const { chatbotStylesConfig } = useContext(ChatbotContext);
  // This component should contain the following components in a scrollable window - ChatbotHeader, ScrollViewMessage, TextInput
  const { title, subtitle, imageUrl, backgroundColor } =
    chatbotStylesConfig.header;
  return (
    <div className="chatbot-container">
      <ChatbotHeader
        title={title}
        subtitle={subtitle}
        brandImage={imageUrl}
        backgroundColor={backgroundColor}
      />
      <MessageView />
      <InputContainer />
    </div>
  );
};

export default ChatbotWindow;
