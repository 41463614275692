import React, { createContext, useState } from "react";

const ChatbotContext = createContext();

const ChatbotProvider = ({ children }) => {
  const [toggleChatbot, setToggleChatbot] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Hi, I'm Inika, your personal virtual stylist. Who are you shopping for today?",
      user: false,
    },
  ]);
  const [outfitList, setOutfitList] = useState([]);
  const [gender, setGender] = useState("");

  // Config object for styles
  const chatbotStylesConfig = {
    header: {
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvYa3ufyY8BKre9hMsn__-Jg3-zsxl7aYBw&s",
      backgroundColor: "#003057",
      title: "Buzz",
      subtitle: "Your personal virtual stylist",
    },
    triggerButton: {
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvYa3ufyY8BKre9hMsn__-Jg3-zsxl7aYBw&s",
    },
    userMessage: {
      messageColor: "#B3A369",
      userMessageIcon:
        "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
      textColor: "#000000",
    },
    botMessage: {
      messageColor: "#003057",
      botMessageIcon:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvYa3ufyY8BKre9hMsn__-Jg3-zsxl7aYBw&s",
      textColor: "#FFFFFF",
    },
  };

  /**
   * @param None
   * @returns None - just toggles the context variable that is consumed in other components.
   * This method allows the user to toggle between the chatbot trigger button and the message window
   */
  const changeBotVisibility = () => {
    setToggleChatbot(!toggleChatbot);
  };

  const addMessage = async (input) => {
    setMessages((prevMessages) => [...prevMessages, input]);
  };

  const replaceMessage = async (input) => {
    setMessages((prevMessages) => [...prevMessages.slice(0, -1), input]);
  };

  const addOutfit = async (outfit) => {
    setOutfitList((prevOutfits) => [...prevOutfits, outfit]);
  };

  const clearOutfit = async () => {
    setOutfitList([]);
  };

  return (
    <ChatbotContext.Provider
      value={{
        toggleChatbot,
        changeBotVisibility,
        addMessage,
        replaceMessage,
        addOutfit,
        clearOutfit,
        chatbotStylesConfig,
        messages,
        outfitList,
        gender,
        setGender,
        setMessages,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};

export { ChatbotContext, ChatbotProvider };
