import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ChatbotContext } from "./ChatbotProvider"; // Provider context import

const UserIcon = ({ imgSrc }) => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "Roboto",
        color: "black",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      <img
        alt=""
        src={imgSrc}
        style={{
          maxWidth: "43px",
          maxHeight: "43px",
          borderRadius: 50,
          width: "auto",
          height: "auto",
          border: "1px solid black",
        }}
      />
    </div>
  );
};

const TextUserMessage = ({ message }) => {
  const { chatbotStylesConfig } = useContext(ChatbotContext);

  const { userMessageIcon, messageColor, textColor } =
    chatbotStylesConfig.userMessage;
  return (
    <div
      style={{
        paddingLeft: 29,
        paddingRight: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: "0px 0px",
        marginBottom: "0.5em",
        borderStyle: "solid",
        borderColor: "rgba(164, 198, 229, 0.5)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "right",
          borderRadius: "20px",
          marginRight: 10,
          padding: 20,
          border: "2px solid #ddd",
          backgroundColor: messageColor,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "max-content",
        }}
      >
        <span style={{ fontSize: "12px", color: textColor }}>{message}</span>
      </div>
      <UserIcon imgSrc={userMessageIcon}></UserIcon>
    </div>
  );
};

export default TextUserMessage;
