// Library Imports
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
// Style Imports
import "./Chatbot.css";
// Provider context import
import { ChatbotContext } from "./ChatbotProvider";

const HeaderIcon = ({ brandImage }) => {
  return (
    <div
      className="chatbot-header-icon"
      style={{ backgroundImage: `url(${brandImage})` }}
    ></div>
  );
};

const ChatbotHeader = ({ title, subtitle, brandImage, backgroundColor }) => {
  const { changeBotVisibility } = useContext(ChatbotContext);
  return (
    <div
      className="chatbot-container-children chatbot-header"
      style={{ backgroundColor: backgroundColor }}
    >
      <HeaderIcon brandImage={brandImage} />
      <div className="chatbot-header-text">
        <div className="chatbot-header-title">{title}</div>
        <div className="chatbot-header-subtitle">{subtitle}</div>
      </div>
      <button
        onClick={changeBotVisibility}
        className="chatbot-header-toggle-button"
      >
        <FontAwesomeIcon
          icon={faMinus}
          style={{ fontSize: "2.5vh" }}
          className="chatbot-header-toggle-button-icon"
        />
      </button>
    </div>
  );
};

export default ChatbotHeader;
