import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMale, faFemale } from "@fortawesome/free-solid-svg-icons";
// Provider context import
import { ChatbotContext } from "./ChatbotProvider";

export const GenderButton = ({ gender }) => {
  const { setGender, addMessage } = useContext(ChatbotContext);
  const genderIcon =
    gender == "male" ? (
      <FontAwesomeIcon
        icon={faMale}
        style={{ fontSize: "3em", justifyContent: "flex-start" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faFemale}
        style={{ fontSize: "3em", justifyContent: "flex-start" }}
      />
    );
  return (
    <button
      style={{
        color: "#000000",
        border: "2px solid #000000",
        backgroundColor: "white",
        borderRadius: "50%",
        width: "5em",
        height: "5em",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => {
        setGender(gender);
        const setGenderMessage = {
          text: "Your gender has been selected! What would you like to shop for",
          user: false,
        };
        addMessage(setGenderMessage);
      }}
    >
      {genderIcon}
    </button>
  );
};
