import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";
import "./inikaTag.css";
import inika_logo from "../Inika_logo.png";
import LogoCircle from "../LogoCircle.png";

import axios from "./ServerConnection";

import Chatbot from "./components/Chatbot/Chatbot";

import "font-awesome/css/font-awesome.min.css";

//import React from 'react';
// import Chatbot from "../components/Chatbot/Chatbot";
import "react-chatbot-kit/build/main.css";
import "./inikaTag.css";
import "./react-chatbot-stylesCHAT.css";

import config from "./configCHAT";
import ActionProvider, { latestoutfit } from "./ActionProviderCHAT";
import MessageParser from "./MessageParserCHAT";
import { Chat } from "@mui/icons-material";

export const InikaChat = () => {
  const history = useHistory();
  const [showChatbot, setShowChatbot] = useState(false);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  // Check if they are Autheticated

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/login");
    }
  });

  // Logout Function
  const logout = async () => {
    await signOut(auth);
    history.push("/login");
  };

  return (
    <div className="inikaChatBG">
      {/* Navigation Bar Code  */}
      <div class="dropdown" className="NavChat">
        <a
          className="ChatDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListChat"></div>
          <div class="twoDemoListChat"></div>
          <div class="threeDemoListChat"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/demo">
            Explore
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      {/* Logo Code  */}

      <div className="ChatImageContainer">
        <img src={inika_logo} alt="Centered Image" className="ChatImage" />
      </div>

      <div>
        <Chatbot></Chatbot>
      </div>
    </div>
  );
};
