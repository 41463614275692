import React from "react";

import "./FAQ.css";

const FAQ = (props) => {
  const options = [
    {
      text: "Shipping and Delivery",
      handler: props.actionProvider.handleS_and_D,
      id: 1,
    },
    { text: "Orders and Tracking", 
    handler: props.actionProvider.handleTrackOrder, 
    id: 2 
    },
    { text: "Payments", 
    handler: props.actionProvider.handlePayments, 
    id: 3 
    },
    { text: "Returns, Refunds and Cancellations", 
    handler: props.actionProvider.handleReturnRefundsCancellations, 
    id: 4 
    },
    { text: "Refunds", 
    handler: props.actionProvider.handleRefunds, 
    id: 5 
    },
    { text: "Fair Usage Policy", 
    handler: props.actionProvider.handlePolicy, 
    id: 6
    },
    { text: "Credits", 
    handler: props.actionProvider.handleCredits, 
    id: 7 
    },
    { text: "E-gift Voucher", 
    handler: props.actionProvider.handleVoucher, 
    id: 8
    },
    { text: "Contact Us", 
    handler: props.actionProvider.handleContact, 
    id:9
    },
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrack, 
    id: 10
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default FAQ;
