import React, { useState, useEffect } from "react";
import "./payments.css";
import inika_logo from "../../Inika_logo.png";
import { auth2 } from "../../firebase";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const CheckoutForm = () => {
  const history = useHistory();
  const [stripeUser, setStripeUser] = useState("");

  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };
  useEffect(() => {
    const handleFileChange = async () => {
      const authState = onAuthStateChanged(auth2, (user) => {
        if (user) {
          const uid = user.uid;
          // ...
        } else {
          history.push("/shopify-login");
          // User is signed out
          // ...
        }
      });
      try {
        await auth2.authStateReady();
        const response = await axios.post(
          "https://us-central1-shopify-7a472.cloudfunctions.net/getStripeCustomer",
          {
            user: auth2.currentUser.uid,
          }
        );
        console.log(response.data.stripe_user);
        setStripeUser(response.data.stripe_user);

        // Update progress after each file is uploaded
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    };
    handleFileChange();
  }, []);

  const getStripeId = () => {};

  return (
    <div className="pricingBG">
      {/* Navigation Bar Code  */}
      <div class="dropdown" className="NavTag">
        <a
          className="TagDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListTag"></div>
          <div class="twoDemoListTag"></div>
          <div class="threeDemoListTag"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/shopify-landing">
            Dashboard
          </a>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>
      <div className="TagImageContainer">
        <img src={inika_logo} alt="Centered Image" className="TagImage" />
      </div>
      <div className="PricingTable">
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        <div className="PriceItem">
          <stripe-pricing-table
            pricing-table-id="prctbl_1PInLnP1C7GIUfA7erDBfRmr"
            publishable-key="pk_live_51PA1gcP1C7GIUfA7s4EbuovALA6gnBfzBS1kPBUPRK4sGyMMG51Z0ol4cjcFFbQ8b0dO0QS58iITyXTTgqwhXr7000Pe0IwX8U"
            client-reference-id={stripeUser}
          ></stripe-pricing-table>
        </div>
      </div>
    </div>
  );
};
