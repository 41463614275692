import React, { useState, useContext } from "react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatbotContext } from "./ChatbotProvider";
import axios from "axios";
import { auth } from "../../../firebase";

const InputContainer = () => {
  const [input, setInput] = useState("");
  const {
    messages,
    addMessage,
    replaceMessage,
    outfitList,
    gender,
    addOutfit,
    clearOutfit,
    chatbotStylesConfig,
  } = useContext(ChatbotContext);

  const chatRoute = async (input) => {
    // Decide whether to call edit function or semantic function
    console.log(input);
    console.log(gender);
    if (outfitList.length < 1) {
      // Code that works with the ruby backend formatting
      const outfitListApiResponse = await axios.post(
        "https://api.inika.app/inikaSearchChat",
        {
          search_query: input,
          index_name: "legends",
          gender: gender,
        }
      );

      console.log(outfitListApiResponse.data);

      const outfits = outfitListApiResponse.data[0];
      let urls = outfitListApiResponse.data[1];
      const text = outfitListApiResponse.data[2];
      const productLinks = outfitListApiResponse.data[3];

      console.log(outfits);
      console.log(urls);
      console.log(productLinks);
      console.log(text);

      urls = urls.map((url) => url.replace(/ /g, "%20"));

      outfitList.push(outfits);
      console.log(outfitList);

      return [urls, outfits, text, productLinks];
    } else if (outfitList.length > 0) {
      console.log("Hitting Edit");
      const last_outfit = outfitList[outfitList.length - 1];
      // Code that works with Ruby Backend
      if (last_outfit.length > 1) {
        const outfitListApiResponse = await axios.post(
          "https://api.inika.app/EditOutfitGender",
          {
            search_query: input,
            user: auth.currentUser.uid,
            gender: gender,
            outfit: outfitList[outfitList.length - 1],
          }
        );

        const outfits = outfitListApiResponse.data[0];
        let urls = outfitListApiResponse.data[1][0];
        let productLinks = outfitListApiResponse.data[1][1];
        let text = outfitListApiResponse.data[2];

        urls = urls.map((url) => url.replace(/ /g, "%20"));
        console.log(urls);

        return [urls, outfits, text, productLinks];
      } else {
        const outfitListApiResponse = await axios.post(
          "https:api.inika.app/EditItemGender",
          {
            search_query: input,
            user: auth.currentUser.uid,
            gender: gender,
            outfit: outfitList[outfitList.length - 1],
          }
        );

        const outfits = outfitListApiResponse.data[0];
        let urls = outfitListApiResponse.data[1][0];
        let productLinks = outfitListApiResponse.data[1][1];
        let text = outfitListApiResponse.data[2];

        urls = urls.map((url) => url.replace(/ /g, "%20"));
        console.log(urls);

        return [urls, outfits, text, productLinks];
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    const userMessage = { text: input, user: true };
    addMessage(userMessage);
    const aiMessage = { text: "...", user: false };

    addMessage(aiMessage);
    const response = await chatRoute(input);
    console.log(response);

    const newAiMessage = {
      urls: response[0],
      outfits: response[1],
      text: response[2],
      user: false,
      productLinks: response[3],
    };
    console.log(newAiMessage.productLinks);
    replaceMessage(newAiMessage);
    setInput("");
  };

  const { sendButtonColor } = chatbotStylesConfig.userMessage;
  return (
    <form
      className="chatbot-container-children chatbot-input-form"
      style={{
        display: "flex",
        position: "relative",
        bottom: 0,
        justifyContent: "space-around",
        // flexWrap: "wrap",
      }}
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        value={input}
        style={{ borderRadius: 20, marginLeft: "2%", flex: "1 1 0" }}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message..."
      />
      <button
        type="submit"
        style={{
          borderRadius: 20,
          marginLeft: "1em",
          marginRight: "0.5em",
          flex: "0 1 auto",
          backgroundColor: sendButtonColor,
        }}
      >
        <FontAwesomeIcon icon={faPaperPlane} className="send-button-icon" />
      </button>
    </form>
  );
};

export default InputContainer;
