import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  createUserWithEmailAndPassword,
  connectAuthEmulator,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDKqL4MiaHKsY8LJCH2aRDPktB-R7LlO4M',
  authDomain: 'inika-webpage.firebaseapp.com',
  projectId: 'inika-webpage',
  storageBucket: 'inika-webpage.appspot.com',
  messagingSenderId: '101488670907',
  appId: '1:101488670907:web:0bffeffcebc41cdd25519e'
}

const app = initializeApp(firebaseConfig, 'Main')
const analytics = getAnalytics(app)
export const auth = getAuth(app)

export const register = () => {
  // Receive Inputs
  const email = document.getElementById('email').value
  const password = document.getElementById('password').value
  // Validate Inputs
  if (validate_password(password) === false) {
    alert('Email or Password is not good')
    return
  }
  auth
    .createUserWithEmailAndPassword(email, password)
    .then(() => {
      var user = auth.currentUser
    })
    .catch(error => {
      var error_code = error.code
      var error_message = error.message
      alert(error_message)
    })
}

function validate_password (password) {
  if (password < 6) {
    return false
  } else {
    return true
  }
}

export const db = getFirestore(app)

export const passwordReset = async email => {
  return await sendPasswordResetEmail(auth, email)
}

export const confirmThePasswordReset = async (oobCode, newPassword) => {
  if (!oobCode && !newPassword) return

  return await confirmPasswordReset(auth, oobCode, newPassword)
}

const firebaseConfig2 = {
  apiKey: 'AIzaSyBjhhdG5ayZ-xuU_FFECfjGVPef-u6RYwo',
  authDomain: 'shopify-7a472.firebaseapp.com',
  projectId: 'shopify-7a472',
  storageBucket: 'shopify-7a472.appspot.com',
  messagingSenderId: '26453343690',
  appId: '1:26453343690:web:2e62aeeba6384a5d53e333',
  measurementId: 'G-FR86GSDCVZ'
}

const app2 = initializeApp(firebaseConfig2, 'Shopify')
export const auth2 = getAuth(app2)
