import { useState } from 'react'
import emailjs from 'emailjs-com'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

function useIsMobile () {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
  return isMobile
}

const initialState = {
  name: '',
  email: ''
}
export const Contact = props => {
  const [{ name, email }, setState] = useState(initialState)
  const isMobile = useIsMobile()

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = e => {
    e.preventDefault()
    clearState()

    {
      /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
    }

    emailjs
      .sendForm(
        'service_hwvt664',
        'template_vqzlouu',
        e.target,
        '5NNdZCbaOzC1oEX8r'
      )
      .then(
        result => {
          console.log(result.text)
          clearState()
        },
        error => {
          console.log(error.text)
        }
      )
    clearState()
  }
  return (
    <div>
      <div
        id='contact'
        className='text-center'
        style={{ backgroundColor: '#F4D7BD' }}
      >
        <div className='container' style={{ backgroundColor: '#F4D7BD' }}>
          <div className='col-md-8-offset-2'>
            <div className='row'>
              <div className='section-title-contact'>
                <h2 style={{ color: '#333' }}>
                  {' '}
                  Interested in bringing the most engaging, interactive, and
                  customizable online shopping experience to your customers?
                </h2>
                <p style={{ color: '#222' }}>
                  Enter your information in the form below to join the waitlist
                  to get access to our beta
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row' style={{ marginTop: '-20px' }}>
                  <div className='col-md-6'>
                    <div
                      className='form-group'
                      style={{ width: '50%', float: 'right' }}
                    >
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        style={{
                          border: '1px solid #EEE',
                          borderRadius: '7px'
                        }}
                        placeholder='Company Name'
                        value={name}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div
                      className='form-group'
                      style={{ width: '50%', float: 'left' }}
                    >
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        style={{
                          border: '1px solid #EEE',
                          borderRadius: '7px'
                        }}
                        placeholder='Email'
                        value={email}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div id='success'></div>
                <button
                  type='submit'
                  style={{ marginTop: '20px' }}
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Join the waitlist
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
