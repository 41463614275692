import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import axios from "axios"; // Import axios for making HTTP requests
import { useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Modal from "react-modal";
import searchGlass from "../search_glass.png";

import "font-awesome/css/font-awesome.min.css";

//import React from 'react';
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./inikaSearch.css";
import "./react-chatbot-styles.css";

import config from "./config";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import avatarImage from "../chatbot_avatar.png";
import inika_logo from "../Inika_logo.png";
import camera from "../camera.png";
import Swal from "sweetalert2";

<meta
  http-equiv="Content-Security-Policy"
  content="upgrade-insecure-requests"
></meta>;
export const Chatb = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [getMessage, setGetMessage] = useState({});
  const history = useHistory();

  const [file, setFile] = useState([]);
  const [portfolioFile, setPorfolioFile] = useState([]);
  // const [portfolio, setPortfolio] = useState()
  //const [imageBase64, setImageBase64] = useState('');
  //const [imageList, setImageList] = useState([]);
  //const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [selectedImage, setSelectedImage] = useState(null);
  //const [output, setOutput] = useState('');
  const [showChatbot, setShowChatbot] = useState(true);

  const [input, setInput] = useState("");
  const [results, setResults] = useState(null);
  const [searchOption, setSearchOption] = useState("text");

  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);

  const [fileOutput, setFileOutput] = useState(false);
  const [textOutput, setTextOutput] = useState(false);

  const [spellCheck, setSpellCheck] = useState(false);
  const [modifiedWord, setModifiedWord] = useState("");
  const [classSearch, setClassSearch] = useState("");

  const handleTextInput = (event) => {
    setInput(event.target.value);
  };

  const extractSubstring = (str) => {
    const startIndex = str.indexOf("[");
    const endIndex = str.indexOf("]");

    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      return str.substring(startIndex + 1, endIndex).trim();
    } else {
      return ""; // or handle invalid cases
    }
  };

  useEffect(() => {
    // Function to call your API
    const callApi = async () => {
      try {
        await auth.authStateReady();
        // Make API call
        const response = await axios.post(
          "https://cheaply-shining-pup.ngrok-free.app/searchQuery",
          { message: "shirt", uid: auth.currentUser.uid }
        );
        // Handle the response as needed
      } catch (error) {
        // Handle errors
        console.error("Error calling API:", error);
      }
    };

    // Call the API on component mount
    callApi();
  }, []);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    const base64Data = await convertBase64(file);
    await handleSubmit(event, false, base64Data);
  };

  const handleTextInputChange = async (event) => {
    console.log("text");
    setTextInput(event.target.value);
    // Clear file input
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64Data = fileReader.result.match(/base64,(.*)$/)[1]; // Extract base64 part
        resolve(base64Data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function processPaths(paths, prefix, substring) {
    return paths.map((path) => {
      const index = path.indexOf(substring);
      if (index !== -1) {
        const substringIndex = index + substring.length;
        return prefix + path.substring(substringIndex);
      }
      console.log(path);
      return path;
    });
  }

  function removeLastCharacters(strings, count) {
    return strings.map((str) => str.substring(0, str.length - count));
  }

  const logout = async () => {
    await signOut(auth);
    console.log("signed out");
    history.push("/login");
  };

  const renderImages = () => {
    if (results != null) {
      const urlsArray = Object.values(results);
      return (
        <div>
          {urlsArray[0].map((url, index) => (
            <img
              src={url}
              style={{
                height: "270px",
                width: "200px",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
              key={index}
              alt={`Image ${index + 1}`}
            />
          ))}
        </div>
      );
      if (typeof results === "object") {
        return (
          <div>
            {fileInput && (
              <div style={{ marginBottom: "2%" }}>
                <img
                  src={URL.createObjectURL(fileInput)}
                  style={{ width: "200px", height: "270px" }}
                  alt="Additional Image"
                />
              </div>
            )}

            {/* Map over the array of image URLs and render each as an <img> element */}
            {results.map((base64Image, index) => (
              <img
                key={index}
                src={`data:image/jpeg;base64,${base64Image}`}
                style={{ width: "200px", height: "270px" }}
                alt={`Image ${index}`}
              />
            ))}
          </div>
        );
      } else {
        const result = extractSubstring(results);
        const urlsArray = result.split(",");
        let updatedArray = urlsArray;

        if (classSearch === 1) {
          updatedArray = urlsArray.slice(0, 5);
        }

        const prefix =
          "https://storage.googleapis.com/inika-webpage.appspot.com/".concat(
            auth.currentUser.uid
          );
        const processedPaths = processPaths(
          updatedArray,
          prefix,
          auth.currentUser.uid
        );
        const finalPaths = removeLastCharacters(processedPaths, 1);
        return (
          <div>
            {spellCheck && (
              <div
                style={{
                  marginBottom: "10px",
                  color: "blue",
                  marginBottom: "5%",
                }}
              >
                <span style={{ color: "#555" }}>Showing Results for </span>{" "}
                {modifiedWord}
              </div>
            )}
            {/* Map over the array of image URLs and render each as an <img> element */}
            {finalPaths.map((url, index) => (
              <img
                src={url}
                style={{
                  height: "270px",
                  width: "200px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
                key={index}
                alt="Make sure to include a alt tag, because react might throw an error at build"
              />
            ))}
          </div>
        );
      }
    }
  };

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event, bool = false, file = null) => {
    event.preventDefault();
    const startTime = performance.now();
    // try {
    if (bool) {
      setFileInput(null);
      let searchResults = await axios.post(
        "https://api.inika.app/inikaSearch",
        {
          index_name: "legends",
          user: "UuyUZlQYPtPeIAZSSRcdPi68c1j1",
          search_query: textInput,
        }
      );

      setTextOutput(false);
      setFileOutput(true);

      setResults(searchResults);
    }

    // let apiResponse;

    // if (bool) {
    //   setFileInput(null);

    //   let [responseClass, responseQueryStore] = await Promise.all([
    //     axios.post("https://cheaply-shining-pup.ngrok-free.app/search", {
    //       message: textInput,
    //     }),
    //   ]);

    //   console.log(responseClass.data);

    //   var parsedData = JSON.parse(responseClass.data);
    //   var classInput = parsedData.class;
    //   setClassSearch(parsedData.class);
    //   var input = parsedData.var1;
    //     var spellChecked = parsedData.var2;

    //     if (classInput === 0) {
    //       console.log("0");
    //       setModifiedWord(input);
    //       setSpellCheck(spellChecked);
    //       apiResponse = await processText(textInput);
    //     } else {
    //       console.log("1");
    //       setModifiedWord(input);
    //       setSpellCheck(spellChecked);
    //       apiResponse = await processText2(input);
    //     }

    //     setResults(apiResponse);
    //     setTextOutput(true);
    //     setFileOutput(false);
    //   } else if (!bool) {
    //     // Process file input (assuming it's an image file)
    //     apiResponse = await processImage(file);
    //     console.log(typeof apiResponse.images);
    //     setResults(apiResponse.images);
    //     setTextOutput(false);
    //     setFileOutput(true);
    //   }

    //   const endTime = performance.now();
    //   const duration = endTime - startTime;

    //   console.log(`Execution time: ${duration} milliseconds`);
    // } catch (error) {
    //   console.error("Error processing input:", error);
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error!",
    //     text: "You don't have permission to do that",
    //   });
    // }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/login");
    }
  });

  const handleChatbotResponse = () => {
    // Logic to handle chatbot response
    setShowChatbot(true); // Show the chatbot again
    // Clear uploadedImages state if needed
  };

  const processText = async (text) => {
    console.log("1");
    let [responseClass, responseQueryStore] = await Promise.all([
      axios.post("https://cheaply-shining-pup.ngrok-free.app/cbot", {
        uid: auth.currentUser.uid,
        message: text,
      }),
      axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/StoreQuery",
        { query: textInput, query_type: "Semantic" }
      ),
    ]);
    console.log("Success 1");
    return responseClass.data;
  };

  const processText2 = async (text) => {
    console.log("2");
    let [responseClass, responseQueryStore] = await Promise.all([
      axios.post("https://cheaply-shining-pup.ngrok-free.app/searchQuery", {
        uid: auth.currentUser.uid,
        message: text,
      }),
      axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/StoreQuery",
        { query: textInput, query_type: "General" }
      ),
    ]);
    return responseClass.data;
  };

  const processImage = async (imagedata) => {
    try {
      const response = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/imageCategory",
        {
          image: imagedata,
        }
      );
      const index = response.data.imageIndex;
      console.log(index);

      const response2 = await axios.post(
        "https://sunfish-pure-internally.ngrok-free.app/search",
        {
          image: imagedata,
          cat: index,
        }
      );
      return response2.data;
      // Update progress after each file is uploaded
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
    // Implement logic to process the image using your API
    // Example:
    // const response = await axios.post('your_image_processing_api_endpoint', { imageUrl });
    // return response.data;
  };

  const displayPicture = () => {
    if (fileInput) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        // Display the picture using base64String
      };
      reader.readAsDataURL(fileInput);
    }
  };

  const handleFocus = () => {
    document.querySelector(".search-bar").classList.add("left-align");
  };

  const handleBlur = () => {
    if (!textInput) {
      document.querySelector(".search-bar").classList.remove("left-align");
    }
  };

  return (
    <div className="backgroundSearch">
      <div class="dropdown" className="NavSearch">
        <a
          className="SearchDemoList"
          href="#"
          role="button"
          id="dropdownMenuLinkDemo"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="oneDemoListSearch"></div>
          <div class="twoDemoListSearch"></div>
          <div class="threeDemoListSearch"></div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkDemo">
          <a class="dropdown-item1" href="/demo">
            Explore
          </a>
          <br></br>
          <a class="dropdown-item2" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      <div className="searchLogoContainer">
        <img src={inika_logo} alt="Centered Image" className="centered-image" />
      </div>

      <div className="App-header">
        <div className="SearchContainer">
          <form onSubmit={(e) => handleSubmit(e, true)}>
            <span class="search-icon"></span>
            <div class="search-wrapper">
              <input
                type="text"
                className="search-bar"
                value={textInput}
                onChange={handleTextInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Search..."
              />
            </div>
          </form>
          <div></div>
        </div>
      </div>
      <div style={{ marginTop: "4%" }}>{renderImages()}</div>
    </div>
  );
};

export default Chatb;
