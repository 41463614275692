import React from "react";

import "./Refunds.css";

const Refunds = (props) => {
  const options = [
    {
      text: "How do I request a refund for pay on delivery orders?",
      handler: props.actionProvider.handleRefundDelivery,
      id: 1,
    },
    { text: "How do I request a refund for Pre-paid orders?", 
    handler: props.actionProvider.handleRefundPrePaid, 
    id: 2 
    },
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrackFAQ, 
    id: 3 
    },
    { text: "Main Menu", 
    handler: props.actionProvider.handleBackTrackMain, 
    id: 4
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Refunds;
