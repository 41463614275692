import { FormEvent, useState } from 'react'
import { passwordReset } from '../firebase'
import { Link } from 'react-router-dom'

export const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [emailMessage, setEmailMessage] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await passwordReset(email)
      setEmailMessage(true)
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        alert('User not found, try again!')
        setEmail('')
      }
    }
  }

  return (
    <div
      className='Login Default'
      style={{
        backgroundColor: '#F4D7BD',
        opacity: 1,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      x
      <div
        className='signin-container'
        style={{ width: '80%', textAlign: 'center' }}
      >
        <div>
          <h2>Password Reset</h2>
        </div>
        {emailMessage ? (
          <h3>The Email has been sent; Check your Inbox!</h3>
        ) : (
          <form onSubmit={handleSubmit} style={{ margin: '0 auto' }}>
            <input
              type='email'
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                width: '20%'
              }}
              name='email'
              placeholder='name@email.com'
              onChange={e => setEmail(e.target.value)}
              required
            />
            <div>
              <button
                type='submit'
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  backgroundColor: '#000000',
                  color: '#fff',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  width: '17%',
                  marginTop: '10px'
                }}
              >
                Reset Your Password{' '}
              </button>
            </div>
          </form>
        )}
        <p style={{ marginTop: '20px', fontSize: '14px' }}>
          {' '}
          <Link to='/login' style={{ color: '#333', textDecoration: 'none' }}>
            Back to Login
          </Link>
        </p>
      </div>
    </div>
  )
}
