import React, { useState, useEffect } from "react";
import { Login } from "./components/login";

import { Chatb } from "./components/inikaSearch.js";

import { Demo } from "./components/cardView.jsx";
import { UploadImages } from "./components/uploadImages.jsx";

import { InikaChat } from "./components/inikaChat.jsx";

import { InikaTag } from "./components/inikaTag.jsx";

import { InikaTagActions } from "./components/inikaTagActions.jsx";

import { ViewCatalog } from "./components/viewCatalog.jsx";

import { Contact } from "./components/contact.jsx";

import { ForgotPassword } from "./components/ForgotPassword.jsx";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import InstructionsViewer from "./ExamplePage";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import TOSViewer from "./ToSPage.jsx";
import { QueryClient, QueryClientProvider } from "react-query";
import { Shopify } from "./components/InikaTag/Shopify.jsx";
import { ShopifyLanding } from "./components/InikaTag/InikaTagCredits.jsx";
import { CheckoutForm } from "./components/InikaTag/payments.jsx";
import { InikaTag_Live } from "./components/InikaTag/InikaTag_Live.jsx";
import { UploadImagesTag } from "./components/InikaTag/uploadTagImages.jsx";
import { InikaTagActionsLive } from "./components/InikaTag/inikaTagActionsLive.jsx";
import { ViewCatalogTag } from "./components/InikaTag/ViewTagCatalog.jsx";
import { FileUploadCSV } from "./components/InikaTag/uploadFromCSV.jsx";
import ImagePage from "./components/ImagePageTag.jsx";
import NewHomePage from "./components/NewHomePage/NewHomePage.jsx";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
const queryClient = new QueryClient({});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <BrowserRouter>
          <Switch>
            {/* Routing for the landing page buttons*/}
            <Route exact path="/">
              <NewHomePage />
            </Route>

            {/* Routes for the navigation bars */}
            <Route exact path="/login">
              <Login />
            </Route>

            <Route exact path="/inikaTagActionsLive">
              <InikaTagActionsLive />
            </Route>

            <Route path="/image/:index" component={ImagePage} />
            <Route exact path="/inikaTagCSV">
              <FileUploadCSV />
            </Route>

            <Route exact path="/inikaTagCatalog">
              <ViewCatalogTag />
            </Route>

            <Route exact path="/shopify-login">
              <Shopify />
            </Route>

            <Route exact path="/upload-tag">
              <UploadImagesTag />
            </Route>

            <Route exact path="/inikaSearch">
              <Chatb />
            </Route>

            <Route exact path="/demo">
              <Demo />
            </Route>

            <Route exact path="/uploadImages">
              <UploadImages />
            </Route>

            <Route exact path="/inikaChat">
              <InikaChat />
            </Route>
            <Route exact path="/inikaTagDemo">
              <InikaTag />
            </Route>

            <Route exact path="/inikaTagActions">
              <InikaTagActions />
            </Route>

            <Route exact path="/viewCatalog">
              <ViewCatalog />
            </Route>
            <Route exact path="/reset">
              <ForgotPassword />
            </Route>
            <Route exact path="/InikaTagLive">
              <InikaTag_Live />
            </Route>

            <Route exact path="/shopify-landing">
              <ShopifyLanding />
            </Route>

            <Route exact path="/payment">
              <CheckoutForm />
            </Route>

            <Route exact path="/iOeQ1m313WJg">
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <InstructionsViewer />
            </Route>

            <Route exact path="/nGuG5P21LZxg">
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <TOSViewer />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
};

export default App;
