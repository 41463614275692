// Shimmer.js
import React from "react";
import "./shimmer.css"; // Import the CSS file for the shimmer effect

const Shimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-item"></div>
      <div className="shimmer-item"></div>
      <div className="shimmer-item"></div>
    </div>
  );
};

export const Shimmer_one = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-item"></div>
    </div>
  );
};

export const Shimmer_two = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-item"></div>
      <div className="shimmer-item"></div>
    </div>
  );
};

export default Shimmer;
