import React, { useContext } from "react";
// Provider context import
import { ChatbotContext } from "./ChatbotProvider";

export const ResetButton = () => {
  const { setGender, addMessage, clearOutfit } = useContext(ChatbotContext);
  return (
    <button
      style={{
        color: "#000000",
        border: "2px solid #000000",
        backgroundColor: "white",
        borderRadius: "20%",
        padding: 8,
        width: "8em",
        height: "3em",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        marginTop: "1em",
      }}
      onClick={() => {
        setGender("");
        const resetMessage = {
          text: "Your outfits have been reset, Who would you like to shop for?",
          user: false,
        };
        clearOutfit();
        addMessage(resetMessage);
      }}
    >
      Reset
    </button>
  );
};
