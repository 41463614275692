import { Shop } from "@mui/icons-material";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth2 } from "../../firebase";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";
function useIsMobile() {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  return isMobile;
}

export const Shopify = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginPage, setIsLoginPage] = useState(true);
  const history = useHistory();

  const handleSignIn2 = (event) => {
    signOut(auth2);
    event.preventDefault();

    // Prevent the default form submission behavior
    signInWithEmailAndPassword(auth2, email, password)
      .then(() => {
        history.push("/shopify-landing");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Incorrect Username or Password!",
          text: "Please enter a valid username and password combination.",
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };

  return (
    // CHECK IF LOGGED IN OR NOT. REDIRECT TO THE LANDING PAGE FOR SHOPIFY.
    <div
      className="Login Default"
      style={{
        backgroundColor: "#F4D7BD",
        opacity: 1,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="signin-container">
        <div
          className="signin-form"
          style={{ textAlign: "center", maxWidth: "400px" }}
        >
          <img src="img/PI_logo_V1.png" alt="" />{" "}
          <h2 style={{ textTransform: "none" }}>InikaTag Login</h2>
          <form style={{ margin: "auto" }} onSubmit={handleSignIn2}>
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label htmlFor="email" style={{ marginRight: "10px" }}>
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "400px",
                }}
              />
            </div>
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label htmlFor="password" style={{ marginRight: "10px" }}>
                Password
              </label>
              <p
                style={{
                  marginTop: "20px",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              ></p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "400px",
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#000000",
                color: "#fff",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Sign In
            </button>
          </form>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        ></div>
      </div>
    </div>
  );
};
