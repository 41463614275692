import React from 'react'
import CustomFirstMessageButton from './components/CustomFirstMessage/CustomFirstMessageButton'

const CustomFirstMessage = props => {
  if (props.state.messages.length > 4) return
  return (
    <div
      style={{
        minHeight: 83,
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15
      }}
    >
      <h3
        style={{
          color: '#000000',
          margin: '0 0 20px 0',
          fontSize: '36px'
        }}
      ></h3>
    </div>
  )
}

export default CustomFirstMessage
