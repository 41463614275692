import React from 'react'
import axios from './ServerConnection'
import { createCustomMessage } from 'react-chatbot-kit'
import { auth } from '../firebase'
import { createChatBotMessage } from 'react-chatbot-kit'
import { message } from 'antd'

var outfit_list = []
var prev_state = []
// This function defines all of the actions that the chatbot can perform, and is passed to the MessageParser component.
const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  ...rest
}) => {
  // Handle All go Back Options
  const handleBackTrack = message => {
    const m = createChatBotMessage(
      "Good Morning! Welcome to JACK & JONES! I am Mr. Jack, your AI-powered shopping assistant. I can help you clarify doubts, track orders, raise an issue and do much more. Let's get started!",
      {
        widget: 'options'
      }
    )

    setState(prev => ({
      messages: [...prev.messages, m]
    }))

    prev_state = prev_state.concat('Options')
  }

  const handleBackTrackOrder = message => {
    const m = createChatBotMessage('What Questions Can I help you with', {
      widget: 'TrackOrder'
    })

    setState(prev => ({
      messages: [...prev.messages, m]
    }))

    prev_state = prev_state.concat('FAQ')
  }

  const handleBackTrackFAQ = message => {
    const m = createChatBotMessage(
      'Sure! I can help you with tracking your order. How would you like to proceed with?',
      {
        widget: 'FAQ'
      }
    )

    setState(prev => ({
      messages: [...prev.messages, m]
    }))

    prev_state = prev_state.concat('TrackOrder')
  }

  const handleBackTrackMain = message => {
    const m = createChatBotMessage("Hi! It's Inika Chat, how can I help you?", {
      widget: 'MainMenu'
    })

    setState(prev => ({
      messages: [...prev.messages, m]
    }))

    prev_state = prev_state.concat('Options')
  }

  // Track Order Branhc

  const handleTrackOrder = message => {
    const m = createChatBotMessage(
      'Sure! I can help you with tracking your order. How would you like to proceed with?',
      {
        widget: 'TrackOrder'
      }
    )

    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('TrackOrder')
  }

  const handleMobileNumber = message => {
    const m = createChatBotMessage('Please enter your mobile number!', {
      widget: 'MobileNumber'
    })
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Mobile')
  }

  const handleOrderID = message => {
    const m = createChatBotMessage('Please enter your Order ID!', {
      widget: 'OrderID'
    })
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('OrderID')
  }

  // Return Order

  const handleReturnOrder = message => {
    const m = createChatBotMessage(
      'Please enter your primary contact number to get your order status.',
      {
        widget: 'ReturnOrder'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('ReturnOrder')
  }

  // Cancel Order

  const handleCancelOrder = message => {
    const m = createChatBotMessage(
      'Please enter your primary contact number to get your order status.',
      {
        widget: 'ReturnOrder'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('CancelOrder')
  }

  // Refund Status

  const handleRefundStatus = message => {
    const m = createChatBotMessage(
      'Please enter your primary contact number to get your order status.',
      {
        widget: 'RefundStatus'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('RefundStatus')
  }

  // FAQ

  const handleFAQ = message => {
    const m = createChatBotMessage('What Questions Can I help you with', {
      widget: 'FAQ'
    })
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('FAQ')
  }

  const handleS_and_D = message => {
    const m = createChatBotMessage(
      'Here are some of the common FAQs for Shipping and Delivery',
      {
        widget: 'ShippingDelivery'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('S_and_D')
  }

  const handleDeliveryTime = message => {
    const m = createChatBotMessage(
      'Most orders arrive within 5-10 days of order being confirmed. An order confirmation SMS/Email will be sent to you, post which an SMS/Email notification will be sent, once your order is shipped.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleShippingAvailable = message => {
    const m = createChatBotMessage(
      'Delivery charge of INR 99 will be levied on orders worth INR 2499 and below.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleShippingInternational = message => {
    const m = createChatBotMessage(
      'We only ship within India but accept most international credit cards.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleReceiveOrder = message => {
    const m = createChatBotMessage(
      'If you are not available to take delivery, when our courier partner attempts to deliver your order, the courier service will notify you. Thereafter, an additional attempt will be made to deliver your order, after which your package will be returned to our fulfilment centre. In case of a prepaid order, a refund will be generated as credits into your website account. These credits can be used for future transactions on jackjones.in. Please check CREDITS section in FAQs for more details.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handlePayments = message => {
    const m = createChatBotMessage(
      'Pay on Delivery is available as a payment option in most of the pin codes we deliver to. You can choose to pay via cash, or through a UPI, Wallet or Debit Cards. We support all major domestic and international credit cards, debit cards, wallets and banks for net banking.',
      {
        widget: 'Payments'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Payments')
  }

  const handleReturnRefundsCancellations = message => {
    const m = createChatBotMessage(
      'Here are some of the common FAQs in RETURNS, REFUNDS & CANCELLATIONS',
      {
        widget: 'Returns'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Return Queries')
  }

  const handleCancelOrderQuery = message => {
    const m = createChatBotMessage(
      "Only orders that haven't left the fulfilment centre can be cancelled. To cancel an order, view the order details in My Account >Orders> Select Product> Cancel. If the order has already been shipped from our fulfilment centre, then you can refuse to accept the delivery and send it back with the courier partner. A refund will be generated, after the product is received at our fulfilment centre.",
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleReturnOrderQuery = message => {
    const m = createChatBotMessage(
      'A request for return can only be raised post 24 hours of receipt of product. All return requests must be received within 15 days of delivery. To return a product, view the order details in - My Account > Orders > Select Product > Return. Once the request is processed, the return order will be picked up from the address provided by you while placing the return request. Please provide your product details and the reason for your return, so we can keep improving your shopping experience. Once your return request has been generated, we’ll send you a confirmation via SMS and email.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleReturnPolicy = message => {
    const m = createChatBotMessage(
      'Most items are eligible for returns (and refunds), except for personal use items such as masks, swimwear, innerwear ( boxers, briefs, trunks, vest, pyjamas), sunglasses, fragrances, socks and jewellery. All refundable items should be unused, intact and in their original packaging (labels, tags, boxes).  The refund will be processed post quality inspection of the product(s). This may take 5-7 working days post receipt of goods at our fulfilment centre.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handlePromoQuery = message => {
    const m = createChatBotMessage(
      'If you used a promo code or discount at the time of purchase, you will be credited only for the final amount you paid after using the promo code.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleRefunds = message => {
    const m = createChatBotMessage(
      'Here are some of the common FAQs in REFUNDS',
      {
        widget: 'Refunds'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Refunds')
  }

  const handleRefundDelivery = message => {
    const m = createChatBotMessage(
      'If you had opted for pay on delivery at the time of purchase, you have the option to receive your refund via credits into your website account, or an account refund via a link. To select your preferred mode of refund, please go to My Account> Orders> Select the product/s > Select ëCancelí or ëReturní. You will be directed to a page where you can select the preferred way for initiating a refund. Jack & Jones Credits- refund will be generated as credits into your website account. It takes 2-3 working days for the credits to reflect in your website account after receipt of returned merchandise in our fulfilment centre. For more details, please check the CREDITS section. Account Refund- To receive an account refund, a link will be sent to your registered email address and mobile number, along with an OTP verification code post receipt of returned items at our fulfilment centre. Click on the link to add your bank account details to initiate a refund. Please Note: Refunds are not processed on bank holidays, Sundays and non-working alternate Saturdays.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleRefundPrePaid = message => {
    const m = createChatBotMessage(
      'For pre-paid orders, you can choose to receive the refund back to the payment source account, where the payment was made from, or you can receive the refund as credits in your website account, which can be used for future transactions. To select your preferred mode of refund, please go to My Account> Orders> Select the product/s > Select ëCancelí or ëReturní. You will be directed to a page where you can select the preferred way for initiating a refund. Jack & Jones Credits ñ Kindly check CREDITS section for more details. Back to Source ñ You will receive the refund in card / account used at the time of purchase. The refund will be initiated after receipt of returned merchandise at our fulfilment centre. It may take up to 5 days for the amount to reflect in the source account.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handlePolicy = message => {
    const m = createChatBotMessage(
      'At jackjones.in, it has always been an endeavour to cater effectively to our discerning customer, by offering a seamless and enjoyable shopping experience. However, there are instances where we have seen a misuse of the many services that we offer, especially on ëfree returnsí or failing to accept a ëpay on deliveryí shipment. To circumvent this issue and to be able to upkeep our level of efficiency and customer satisfaction, going forward, the brand will reserve the right to levy a shipping charge or disable the option of pay on delivery and/ or not accept orders altogether from certain pincodes/ registered email ids. We do hope that as a brand patron, you will support us in this and help us to deliver on our brand promise.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleCredits = message => {
    const m = createChatBotMessage(
      '1. The Jack & Jones credits entitle you to shop from jackjones.in only 2. The credits are valid for a period of one year from the date of purchase and/or date of receipt as refund, into your website account 3. The value of 1 Credit is equal to Re 1/- 4. The credits can be redeemed over multiple transactions on jackjones.in 5. Once bought and/or received as refund, the credits will be reflect in your jackjones.in account. Please visit the ëMy Accountsí section to check your total/ balance credits 6. The credits will be redeemed at the Check Out page 7. Any amount in excess of the credits ,must be paid via alternative payment methods like net banking or debit/credit cards 8. Jack & Jones credits and Jack & Jones E-Gift Vouchers CANNOT be redeemed together for a single transaction 9. The credits once bought/ received as refund, are non-returnable and non-refundable. They can only be used on jackjones.in for buying apparel and accessories 10. In case of any dispute regarding the Jack & Jones credits, the decision of Best United India Comforts Private Limited shall be binding. 11. In the event of a return of a product/s paid via credits, the said amount will be refunded as credits in your jackjones.inaccount 12. The credit points cannot be in excess of INR 10,000 at any point in time 13. Jack & Jones Credits cannot be redeemed during certain special promotions/offers on jackjones.in, including a Flat 60% Off Offer, Super Weekend and Super Saturday',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleVoucher = message => {
    const m = createChatBotMessage(
      'Terms & Conditions 1. The e-Gift Voucher entitles you to shop from jackjones.in only 2. The e-Gift Voucher is valid for a period of one year from the date of purchase 4. The e-Gift Voucher can be used for a single transaction only, any remaining value shall be forfeited post the purchase. 5. Only one e-Gift Voucher can be used per transaction 6. Any amount in excess of the value of the e-Gift Voucher value must be paid via alterative payment methods like net banking or debit/credit cards 7. E-Gift Voucher, once bought is non-returnable and non refundable. It can only be used on jackjones.in for buying merchandise 8. In case of any dispute regarding e-Gift Voucher, the decision of Best United India Comforts Private Limited shall be binding. 9. In the event of a return of a product/s paid via e-Gift Voucher, the said amount will be refunded as credit note/voucher that can be used on jackjones.in only. 10. Jack & Jones e-Gift Voucher cannot be redeemed during certain special promotions/offers on jackjones.in , including a Flat 60% Off Offer, Super Weekend and Super Saturday.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  const handleContact = message => {
    const m = createChatBotMessage(
      'We would love to hear from you! Connect with us at help@jackjones.in for any query, feedback or suggestions. Our Customer Centre number is 18002660005, and our lines are open from 10 AM to 7 PM; Monday to Saturday, except for National and Public Holidays. In such an event, please drop us an email, and we will get back to you at the earliest possible.',
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('Options')
  }

  // ----------------------------------------- //

  // Main Menu
  const handleHelp = message => {
    const m = createChatBotMessage(
      "Good Morning! Welcome to JACK & JONES! I am Mr. Jack, your AI-powered shopping assistant. I can help you clarify doubts, track orders, raise an issue and do much more. Let's get started!",
      {
        widget: 'options'
      }
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
    prev_state = prev_state.concat('MainMenu')
  }
  //  ------------------------------------------ //

  const handleChatBot = message => {
    const m = createChatBotMessage(
      "Of Course! Enter your outfit details below and I'll help you out"
    )
    setState(prev => ({
      messages: [...prev.messages, m]
    }))
  }

  // Handle Processing of Track Order //

  const handlePhone = async m => {
    try {
      let response2 = await axios.post(
        'http://localhost:3001/api/queryDatabase/phoneTrack',
        {
          phoneNumber: m
        }
      )

      console.log(response2.data)

      const chatBotMessage = response2.data.map((item, index) => {
        console.log(item)
        // Extract the value associated with key 1
        const Num = item.OrderID
        const val = item.Stage

        // Construct the message format
        return (
          <div>
            Order number {Num} - {val}
          </div>
        )
      })

      const me = createChatBotMessage(chatBotMessage, {
        widget: 'TrackOrder'
      })
      setState(prev => ({
        messages: [...prev.messages, me]
      }))
    } catch (error) {
      const me = createChatBotMessage(
        'Could Not find an order with you associated phone number. Please try again',
        {
          widget: 'TrackOrder'
        }
      )
      setState(prev => ({
        messages: [...prev.messages, me]
      }))
    }
    prev_state = prev_state.concat('TrackOrder')
  }

  const handleOrderTrack = async m => {
    try {
      let response2 = await axios.post(
        'http://localhost:3001/api/queryDatabase/IDTrack',
        {
          ID: m
        }
      )

      console.log(response2.data)

      const chatBotMessage = response2.data.map((item, index) => {
        console.log(item)
        // Extract the value associated with key 1
        const Num = item.OrderID
        const val = item.Stage

        // Construct the message format
        return (
          <div>
            Order number {Num} - {val}
          </div>
        )
      })

      const me = createChatBotMessage(chatBotMessage, {
        widget: 'TrackOrder'
      })
      setState(prev => ({
        messages: [...prev.messages, me]
      }))
    } catch (error) {
      const me = createChatBotMessage(
        'Could Not find an order with you associated phone number. Please try again',
        {
          widget: 'TrackOrder'
        }
      )
      setState(prev => ({
        messages: [...prev.messages, me]
      }))
    }
    prev_state = prev_state.concat('TrackOrder')
  }

  // End of Track Order processing //

  // Processing of Cancel Order //

  // This function is called by MessageParser.jsx.  It's the main workhorse of the chatbot that calls the backend endpoints that's defined in the Flask app (BotApiHandler.py)
  const handleMessage = message => {
    if (prev_state[prev_state.length - 1] === 'Mobile') {
      handlePhone(message)
    }

    if (prev_state[prev_state.length - 1] === 'OrderID') {
      handleOrderTrack(message)
    } else {
      let newest_outfit = outfit_list[outfit_list.length - 1]

      if (outfit_list.length < 1) {
        axios
          .post('https://cheaply-shining-pup.ngrok-free.app/cbot', {
            message: message,
            uid: auth.currentUser.uid
          })

          .then(
            response => {
              console.log(response)
              let text = response.data
              let uri_list = text.split('@')[0]
              let fit = text.split('@')[1]
              // outfit = fit
              let message = createCustomMessage(response.data, 'custom', {
                payload: {
                  message: uri_list,
                  first: 'no'
                }
              })

              setState(prev => ({
                messages: [...prev.messages, message]
              }))
              return fit
            },
            error => {
              console.log(error)
            }
          )
          .then(fit => {
            outfit_list.push(fit)
          })
      }

      if (outfit_list.length > 0) {
        axios
          .post('https://cheaply-shining-pup.ngrok-free.app/edit', {
            message: message,
            outfit: newest_outfit,
            uid: auth.currentUser.uid
          })
          .then(
            response => {
              let text = response.data
              let uri_list = text.split('@')[0]
              let fit = text.split('@')[1]
              // outfit = fit
              let message = createCustomMessage(response.data, 'custom', {
                payload: {
                  message: uri_list,
                  first: 'no'
                }
              })

              setState(prev => ({
                messages: [...prev.messages, message]
              }))
              return fit
            },
            error => {
              console.log(error)
            }
          )
          .then(fit => {
            outfit_list.push(fit)
          })
      }
    }
  }

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: {
            // handleHello,
            //handleDefault,
            //handleJavascriptList,
            handleMessage,
            handleTrackOrder,
            handleMobileNumber,
            handleBackTrack,
            handleBackTrackFAQ,
            handleHelp,
            handleChatBot,
            handleReturnOrder,
            handleBackTrackOrder,
            handleOrderID,
            handleCancelOrder,
            handleRefundStatus,
            handleBackTrackMain,
            handleFAQ,
            handleS_and_D,
            handleShippingAvailable,
            handleShippingInternational,
            handleReceiveOrder,
            handleDeliveryTime,
            handlePayments,
            handleReturnRefundsCancellations,
            handleReturnOrderQuery,
            handleReturnPolicy,
            handleCancelOrderQuery,
            handlePromoQuery,
            handleRefunds,
            handleRefundDelivery,
            handleRefundPrePaid,
            handlePolicy,
            handleCredits,
            handleVoucher,
            handleContact
          }
        })
      })}
    </div>
  )
}
export const outfits = outfit_list
export default ActionProvider
