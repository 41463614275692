import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth2 } from "../../firebase";
import Shimmer from "../Loading_Animations/shimmer";
import "../inikaTag.css";
import inika_logo from "../../Inika_logo.png";
import axios from "axios";
import { doc } from "firebase/firestore";
import {
  Downloading,
  SignalWifiStatusbarNullTwoTone,
} from "@mui/icons-material";
import Swal from "sweetalert2";

export const InikaTagActionsLive = () => {
  const location = useLocation();

  const [textList, setTextList] = useState([]);

  const imageInputRef = useRef(null);
  const [parsedResult, setParsedResult] = useState(null);
  const [parsedResults, setParsedResults] = useState([]);
  const [tags, setTags] = useState([]);
  const tagInputRef = useRef(null);
  const selectedImagesIds = location.state?.selectedImageURLs || [];
  const imageData = location.state?.imageData || [];

  const [taggedImages, setTaggedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedImages = imageData.filter((image) =>
    selectedImagesIds.includes(image.id)
  );

  // console.log(selectedImages)

  // Function to append a value to parsedResults
  const appendToResults = (newValue) => {
    setParsedResults((prevResults) => [...prevResults, newValue]);
  };

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  const handleTagInputKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const newTags = event.target.value
        .split(",") // Split input by comma
        .map((tag) => tag.trim()) // Trim each tag
        .filter((tag) => tag !== ""); // Remove empty tags

      setTags((prevTags) => [...prevTags, ...newTags]);
      event.target.value = ""; // Clear the input field
    }
  };

  const clearAllTag = () => {
    setTags([]);
  };

  const [editableTag, setEditableTag] = useState(null);
  const [resultTags, setResultTags] = useState(parsedResult);

  const handleToggleEdit = (key) => {
    setEditableTag(key);
  };

  const handleTagChange = (e, key) => {
    if (e.key === "Enter") {
      setEditableTag(null); // Make it non-editable when Enter is pressed
      setResultTags({ ...resultTags, [key]: e.target.value });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  async function getImageLargestDimension(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const largestDimension = Math.max(img.width, img.height);
        resolve(largestDimension);
      };
      img.onerror = (error) => {
        reject(`Failed to load image at ${url}: ${error}`);
      };
    });
  }

  async function getLargestDimensions(images) {
    const largestDimensions = [];

    for (const url of images) {
      try {
        const largestDimension = await getImageLargestDimension(url);
        largestDimensions.push(largestDimension);
      } catch (error) {
        console.error(error);
        // You can handle the error here (e.g., push a default value or continue)
      }
    }

    return largestDimensions;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      // setUploadedImage(selectedImages[i]);
      // const image = downloadImage(selectedImages[i]);
      // const image =  await getImageBlobFromUrl(selectedImages[i]);

      // use server to solve cors error and directly take base64 as response
      // cors error is only because of browser you cant do anything in client side
      // const base64Data = await fetchBase64Image(selectedImages[i]);

      formData.append("tags", textList.join(","));
      if (imageInputRef.current) {
        imageInputRef.current.value = "";
      }

      // console.log(textList);
      // console.log(base64Data);
      setTextList([]);

      console.log("startTs", Date());

      const image_urls = [];
      for (const image in selectedImages) {
        image_urls.push(selectedImages[image]["url"]);
      }

      console.log(image_urls);

      const image_dimensions = await getLargestDimensions(image_urls);
      console.log(image_dimensions);

      const response = await axios.post(
        // "https://us-central1-inika-webpage.cloudfunctions.net/auto-tag ",
        // "https://us-central1-inika-webpage.cloudfunctions.net/tagUrls",
        "https://us-central1-shopify-7a472.cloudfunctions.net/tagImages",
        {
          image_urls: selectedImages,
          tag_list: tags,
          user: auth2.currentUser.uid,
          dimensions: image_dimensions,
        }
      );

      // console.log(response.data);
      // setJsonResult(response.data);
      // Extracting the JSON string from the result
      const result_data = response.data.result;
      setParsedResults([]);

      const tagsReqBody = [];

      for (let i = 0; i < result_data.length; i++) {
        try {
          const jsonString = result_data[i].result.match(/\{([^)]+)\}/)[0];

          const parsedResult = {
            tags: JSON.parse(jsonString),
          };

          console.log(JSON.parse(jsonString));

          const urlToId = result_data[i]["metadata"]["url"];
          const imgId = result_data[i]["metadata"]["id"];
          // Extract the required part (0cde01d1-75e8-4727-a6bd-ff3771dc6541)
          tagsReqBody.push({
            id: imgId,
            tags: parsedResult["tags"],
          });

          // Correcting the key to "image_url"
          parsedResult["url"] = urlToId; // Corrected key

          // console.log(result_data[i]["metadata"]["url"]); // Corrected key

          appendToResults(parsedResult);
          console.log("result_parsed:" + parsedResult);
          setParsedResult(parsedResult);
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      }
      setIsLoading(false);

      const tagsResponse = await axios.post(
        "https://us-central1-shopify-7a472.cloudfunctions.net/add-tags-db",
        {
          user: auth2.currentUser.uid,
          tagsData: tagsReqBody,
        }
      );

      console.log(tagsResponse.data.url_list);
      setTaggedImages(tagsResponse.data.url_list);

      console.log("endTs", Date());
      const tagOutputCards = document.getElementsByClassName("tag-output-card");

      for (let i = 0; i < tagOutputCards.length; i++) {
        tagOutputCards[i].style.display = "flex";
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      if (error.response) {
        if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Out of Tagging Tokens",
          });
        }
        if (error.response.status === 405) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "No Enough Credits",
          });
        }
      }
    }
  };

  const getImageByUrl = (url) => {
    if (parsedResults && parsedResults.length > 0) {
      // console.log(parsedResults)
      // console.log(url)
      return parsedResults.find((item) => item.url === url);
    } else {
      return null;
    }
  };

  const renderUploadedImageByIndex = (imageUrl) => {
    if (imageUrl) {
      return (
        <div className="uploaded-image-container">
          <img
            src={imageUrl}
            alt="Uploaded"
            // style={{ height: "270px", width: "200px" }}
          />
        </div>
      );
    }
    return null;
  };

  const history = useHistory();

  // Check if they are Autheticated

  onAuthStateChanged(auth2, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/shopify-login");
    }
  });

  // Logout Function
  const logout = async () => {
    await signOut(auth2);
    console.log("signed out");
    history.push("/shopify-login");
  };
  return (
    <div className="inikaTagBG">
      {/* Navigation Bar Code  */}

      <div className="main">
        <div className="tags-section">
          <div class="tag-input-section ">
            <div className="base-card tag-input-text">
              <span className="subtitle">Tags</span>

              <input
                className="tag-input-text-field"
                type="text"
                ref={tagInputRef}
                placeholder="Enter tags here "
                onKeyPress={handleTagInputKeyPress}
              />
              <div className="tag-list">
                <TagList tags={tags} setTags={setTags} />
              </div>
            </div>

            <div class="btn-section">
              <button onClick={clearAllTag} class="btn">
                Clear all
              </button>
              <button
                class="btn"
                onClick={handleSubmit}
                disabled={!selectedImages || tags.length === 0}
              >
                Generate tags
              </button>
            </div>
          </div>
        </div>
        <div className="result-container">
          <h3 className="subtitle">Results</h3>
          <div className="result-container-images">
            {selectedImages &&
              Object.entries(selectedImages).map((data, index) => (
                <div key={index} class="tag-output-card" id="tag-output-card">
                  <div class="image-output-card">
                    <div style={{ float: "left", margin: "0 auto" }}>
                      {renderUploadedImageByIndex(data[1]["url"])}
                      {/* { console.log(data[1]["url"])} */}
                    </div>
                  </div>
                  <div class="output-tag-list-container">
                    <div class="output-tag-list">
                      {!parsedResult && isLoading && <Shimmer />}

                      {parsedResults &&
                        getImageByUrl(data[1]["url"]) &&
                        getImageByUrl(data[1]["url"]).tags &&
                        Object.entries(getImageByUrl(data[1]["url"]).tags).map(
                          ([key, value], index) =>
                            // Check if the key is not 'image_url'
                            key !== "url" && (
                              <div className="output-tag-list-item" key={index}>
                                {editableTag !== key ? (
                                  <span onClick={() => handleToggleEdit(key)}>
                                    {key}: {value}
                                  </span>
                                ) : (
                                  <input
                                    type="text"
                                    defaultValue={value}
                                    onKeyPress={(e) => handleTagChange(e, key)}
                                    onBlur={() => setEditableTag(null)} // Make it non-editable when blurred
                                    autoFocus // Focus on the input when it becomes editable
                                  />
                                )}
                              </div>
                            )
                        )}

                      {!parsedResult && !isLoading && (
                        <div>
                          <h1>Input some tags to generate result.</h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TagList = ({ tags, setTags }) => {
  const [editableIndex, setEditableIndex] = useState(null);
  const [editedTag, setEditedTag] = useState("");

  const handleTagClick = (index, tag) => {
    setEditableIndex(index);
    setEditedTag(tag);
  };

  const handleTagChange = (event) => {
    setEditedTag(event.target.value);
  };

  const handleTagBlur = (index) => {
    setEditableIndex(null);
    // Here you can update the tag in your state or wherever it's stored
    // For example:
    const updatedTags = [...tags];
    updatedTags[index] = editedTag;
    setTags(updatedTags);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleTagBlur(index);
    }
  };

  return (
    <div className="tag-list">
      {tags.map((tag, index) => (
        <span key={index} className="tag-item">
          {editableIndex === index ? (
            <input
              type="text"
              value={editedTag}
              onChange={handleTagChange}
              onBlur={() => handleTagBlur(index)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              autoFocus
            />
          ) : (
            <span onClick={() => handleTagClick(index, tag)}>{tag}</span>
          )}
        </span>
      ))}
    </div>
  );
};
