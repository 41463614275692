import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ChatbotContext } from "./ChatbotProvider"; // Provider context import

const ImageMessageContainer = ({ imgSrcs }) => {
  const { chatbotStylesConfig } = useContext(ChatbotContext);
  const { botMessageIcon, messageColor } = chatbotStylesConfig.botMessage;
  const handleClick = (event) => {
    console.log("Element clicked:", event.target);
    // Add firebase  injection
  };
  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 0,
        paddingRight: 29,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: "0px 0px",
        borderStyle: "solid",
        borderColor: "rgba(164, 198, 229, 0.5)",
        marginLeft: "40px",
      }}
    >
      {/* <UserIcon></UserIcon> */}
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "20px",
          border: "2px solid #ddd",
          padding: "20px",
          backgroundColor: messageColor,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "max-content",
        }}
      >
        {imgSrcs[0].map((imgSrc, index) => (
          <a
            href={imgSrcs[1][index]}
            key={index}
            onClick={handleClick}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={imgSrc}
              style={{
                flex: "1 1 48%",
                maxWidth: "85%",
                // height: "140px",
                // width: "100px",
                padding: "10px",
                borderRadius: "20px",
                transition: "transform 0.2s",
                cursor: "pointer",
              }}
              key={index}
              alt={`${imgSrcs[1][index]}`}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

// ImageMessageContainer.propTypes = {
//   imgSrcs: PropTypes.arrayOf(
//     PropTypes.shape({
//       src: PropTypes.string.isRequired
//     })
//   ).isRequired
// };

export default ImageMessageContainer;
