import React from 'react'
import avatarImage from '../chatbot_avatar.png'

import './inikaSearch.css'

const UserIcon = () => {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        backgroundColor: 'transparent',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        font: 'Roboto',
        color: 'black',
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '15px',
        marginLeft: '5px'
      }}
    ></div>
  )
}

const CustomBotMessage = props => {
  // const isBlink = props.payload.first;
  const { message, isBotMessage } = props.payload
  let message_result = message.replaceAll("'", '')
  message_result = message_result.replaceAll('[', '')
  message_result = message_result.replaceAll(']', '')
  message_result = message_result.replaceAll('\\\\', '/')
  message_result = message_result.replaceAll(' ', '')
  message_result = message_result.split(',')
  let first_item = 'closet/flanel.jpg'
  let second_item = String(message_result[0])
  let imagesPool = [
    { src: second_item },
    { src: 'closet/flanel.jpg' },
    { src: 'closet/flanel.jpg' }
  ]
  let images = []
  for (let i = 0; i < message_result.length; i++) {
    let image = String(message_result[i])
    images.push({ src: image })
  }

  return (
    <div
      style={{
        minHeight: 83,
        paddingLeft: 29,
        paddingRight: 29,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderWidth: '0px 0px',
        borderStyle: 'solid',
        borderColor: 'rgba(164, 198, 229, 0.5)'
      }}
    >
      <div
        style={{
          alignSelf: 'flex-start',
          marginTop: 20,
          paddingRight: '30px'
        }}
      >
        <UserIcon />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 0,
          marginRight: 40,
          paddingTop: 35,
          paddingBottom: 30
        }}
      >
        <div>
          <span
            style={{
              color: '#333333',

              fontSize: '24px',
              fontWeight: 200
            }}
          >
            {/* {message_result} */}
            {images.map((imgSrc, index) => (
              <img
                src={imgSrc.src}
                style={{
                  height: '270px',
                  width: '200px',
                  paddindLeft: '10px',
                  paddingRight: '10px',
                  borderRadius: '50px',
                  marginTop: '5px',
                  marginBottom: '5px'
                }}
                key={index}
                alt='Make sure to include a alt tag, because react might throw an error at build'
              />
            ))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CustomBotMessage
