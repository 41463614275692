import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import inika_logo from '../Inika_logo.png'

function useIsMobile () {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  return isMobile
}
export const Navigation = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    })

    // Clean up the listener when the component unmounts
    return () => unsubscribe()
  }, [])

  const isMobile = useIsMobile()
  const mobile = isMobile
  const nav_className = isMobile
    ? 'nav navbar-nav '
    : 'nav navbar-nav navbar-right'
  return (
    <nav
      id='menu'
      className='navbar navbar-default navbar-fixed-top'
      style={{ backgroundColor: '#F4D7BD', opacity: 1, height: '80px' }}
    >
      <div className='container' style={{ width: '95%' }}>
        <div className='navbar-header' style={{ float: 'left' }}>
          <img
            src={inika_logo}
            style={{
              width: '35px',
              float: 'left',
              marginRight: '20px',
              marginTop: '7px'
            }}
          ></img>

          <a
            className='navbar-brand page-scroll'
            href='/#page-top'
            style={{ color: '#C55137', marginLeft: '-20px' }}
          >
            Project Inika
          </a>
        </div>

        {isMobile && (
          <div
            class='dropdown'
            className='NavChat'
            style={{ marginLeft: '70%' }}
          >
            <a
              className='ChatDemoList'
              href='#'
              role='button'
              id='dropdownMenuLinkDemo'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <div
                class='oneDemoListChat'
                style={{ backgroundColor: '#444' }}
              ></div>
              <div
                class='twoDemoListChat'
                style={{ backgroundColor: '#444' }}
              ></div>
              <div
                class='threeDemoListChat'
                style={{ backgroundColor: '#444' }}
              ></div>
            </a>
            <div
              class='dropdown-menu'
              aria-labelledby='dropdownMenuLinkDemo'
              style={{
                marginLeft: '-10%',
                backgroundColor: '#F4D7BD',
                marginTop: '30%',
                border: 'none',
                minWidth: '80px'
              }}
            >
              <a class='dropdown-item2' href='/' style={{ marginLeft: '20%' }}>
                Home
              </a>
              <br></br>
              <a
                class='dropdown-item1'
                href='#about'
                style={{ marginLeft: '20%' }}
              >
                About
              </a>
              <br></br>
              <a
                class='dropdown-item1'
                href='#team'
                style={{ marginLeft: '20%' }}
              >
                Team
              </a>
            </div>
          </div>
        )}
        {!isMobile && (
          <ul className={'nav navbar-nav navbar-right'}>
            <li>
              <a href='/'>Home</a>
            </li>
            <li>
              <a href='/#about'>About</a>
            </li>
            <li>
              <a href='/#team'>Team</a>
            </li>
            <li>
              <a href='/shopify-login'>Inika Tag</a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  )
}
