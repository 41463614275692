import React from "react";

import "./Options.css";

const Options = (props) => {
  const options = [
    {
      text: "Track Order",
      handler: props.actionProvider.handleTrackOrder,
      id: 1,
    },
    { text: "Return Order", 
    handler: props.actionProvider.handleReturnOrder,
    id: 2 },
    { text: "Cancel Order", 
    handler: props.actionProvider.handleCancelOrder, 
    id: 3 },
    { text: "Refund Status", 
    handler: props.actionProvider.handleRefundStatus,
    id: 4 },
    { text: "FAQ", 
    handler: props.actionProvider.handleFAQ,
    id: 5 },
    { text: "Go Back", 
    handler: props.actionProvider.handleBackTrackMain, 
    id: 6 },
  ];

  const buttonsMarkup = options.map((option) => (

    <div>

    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
    </div>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
