import React from "react";
import ChatbotContainer from "./ChatbotContainer";
import { ChatbotProvider } from "./ChatbotProvider";

function Chatbot() {
  return (
    <ChatbotProvider>
      <ChatbotContainer />
    </ChatbotProvider>
  );
}

export default Chatbot;
