import React from 'react'
import tos_1 from './ToS-1.png'
import tos_2 from './ToS-2.png'

const TOSViewer = () => {
  return (
    <div>
      <div
        style={{
          background: '#E9D8C9',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img style={{ width: '80%' }} src={tos_1} alt='...' />
      </div>
      <div
        style={{
          background: '#E9D8C9',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img style={{ width: '80%' }} src={tos_2} alt='...' />
      </div>
    </div>
  )
}
export default TOSViewer
