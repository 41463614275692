import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";
import "./inikaTag.css";
import dropdown_icon from "../chevron.png";
import axios from "axios";
import Swal from "sweetalert2";
import Shimmer, { Shimmer_one, Shimmer_two } from "./shimmer.jsx";
import { SettingsBackupRestoreTwoTone } from "@mui/icons-material";

export const InikaTagActions = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);

  const imageInputRef = useRef(null);
  const [parsedResult, setParsedResult] = useState(null);
  const [parsedResults, setParsedResults] = useState([]);
  const selectedImagesIds = location.state?.selectedImageURLs || [];
  const imageData = location.state?.imageData || [];
  const selectedImages = imageData.filter((image) =>
    selectedImagesIds.includes(image.id)
  );
  const [descriptions, setDescriptions] = useState([]);
  const [textList, setTextList] = useState([]);
  const [tags, setTags] = useState([]);
  const [prefs, setPrefs] = useState(null);
  const [filters, setFilters] = useState([]);
  const tagInputRef = useRef(null);

  const [language, setLanguage] = useState("English");

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleTagsUpdate = async () => {
    // Initialize filters with existing tags if filters array is empty
    if (filters.length === 0) {
      setFilters(tags.map((tagname, index) => getTagTypes(tagname)));
    } else {
      // Add new tags with empty filters
      const updatedFilters = [...filters];
      tags.forEach((tagname, index) => {
        if (!updatedFilters[index]) {
          updatedFilters[index] = getTagTypes(tagname);
        } else {
          updatedFilters[index] = getTagTypes(tagname);
        }
      });
      setFilters(updatedFilters);
    }
  };

  function getTagTypes(tagname) {
    if (prefs && prefs.hasOwnProperty(tagname)) {
      return prefs[tagname];
    } else {
      return [];
    }
  }

  const fetchFilters = async () => {
    // Code inside this block runs when the component mounts for the first time

    const filterResponse = await axios.post(
      "https://us-central1-inika-webpage.cloudfunctions.net/get_tag_prefs",
      {
        user: auth.currentUser.uid,
      }
    );

    setPrefs(filterResponse.data.tag_pref);
  };

  // Function to append a value to parsedResults
  const appendToResults = (newValue) => {
    setParsedResults((prevResults) => [...prevResults, newValue]);
  };

  const handleTagInputKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const newTags = event.target.value
        .toLowerCase()
        .split(",") // Split input by comma
        .map((tag) => tag.trim()) // Trim each tag
        .filter((tag) => tag !== ""); // Remove empty tags

      setTags((prevTags) => {
        const uniqueTags = new Set(prevTags);
        newTags.forEach((tag) => uniqueTags.add(tag));
        return [...uniqueTags];
      });

      event.target.value = ""; // Clear the input field
    }
  };

  const clearAllTag = () => {
    setTags([]);
  };

  const createTagFilterJson = () => {
    const tagFilterJson = {};
    tags.forEach((tag, index) => {
      tagFilterJson[tag] = [...new Set(filters[index])];
    });
    return tagFilterJson;
  };

  const handleSave = async () => {
    const tagFilters = createTagFilterJson();
    let [tagsResponse, embedResponse] = await Promise.all([
      axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/store_tag_prefs",
        {
          user: auth.currentUser.uid,
          tagsPref: tagFilters,
        }
      ),
      axios.post(
        "https://cheaply-shining-pup.ngrok-free.app/create_tag_preference_embed",
        {
          user: auth.currentUser.uid,
          preferences: tagFilters,
        }
      ),
    ]);
  };

  const handleDescriptionGeneration = async () => {
    setIsLoadingDescription(true);
    setDescriptions([]);
    console.log(language);

    let lang = "EN";
    try {
      const descriptionResponse = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/generateImageDescriptions",
        {
          user: auth.currentUser.uid,
          images: selectedImages,
          language: language,
        }
      );
      const descriptionDictionary = descriptionResponse.data.descriptions;
      setDescriptions([]);
      const descriptions_list = [];
      const descriptionResults = [];
      for (let i = 0; i < selectedImages.length; i++) {
        var currentDescription = descriptionDictionary[selectedImages[i]["id"]];
        const parsedResult = {
          tags: currentDescription,
          url: selectedImages[i]["url"],
        };
        console.log(parsedResult);
        descriptionResults.push(parsedResult);
        descriptions_list.push(parsedResult);
      }
      setDescriptions(descriptionResults);
      /* Add The Descirptiosn to Firebase */

      const addDescriptionResult = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/add_description_db",
        {
          user: auth.currentUser.uid,
          description: descriptions_list,
        }
      );

      /*  */
      console.log(descriptions);
    } catch (error) {
      console.error("Error submitting data:", error);
      if (error.response) {
        if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Out of Tagging Tokens",
          });
        }
        if (error.response.status === 405) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Too many tags",
          });
        }
      }
    } finally {
      setIsLoadingDescription(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      formData.append("tags", textList.join(","));

      if (imageInputRef.current) {
        imageInputRef.current.value = "";
      }

      setTextList([]);

      const response = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/tag-multiple-urls-languages",
        {
          image_urls: selectedImages,
          tag_list: tags,
          user: auth.currentUser.uid,
          language: language,
        }
      );

      // Extracting the JSON string from the result
      const result_data = response.data.result;
      setParsedResults([]);

      const tagsReqBody = [];
      const tagPrefReqBody = [];

      for (let i = 0; i < result_data.length; i++) {
        try {
          //const jsonString = result_data[i].result.match(/\{([^)]+)\}/)[0];

          // Parsing the JSON string
          const jsonString = result_data[i].result;
          const parsedResult = {
            tags: jsonString,
          };

          const urlToId = result_data[i]["metadata"]["url"];
          const imgId = result_data[i]["metadata"]["id"];
          // Extract the required part (0cde01d1-75e8-4727-a6bd-ff3771dc6541)
          tagsReqBody.push({
            id: imgId,
            tags: parsedResult["tags"],
          });

          // Correcting the key to "image_url"
          parsedResult["url"] = urlToId; // Corrected key

          // console.log(result_data[i]["metadata"]["url"]); // Corrected key
          console.log(parsedResult);
          appendToResults(parsedResult);
          setParsedResult(parsedResult);

          tagPrefReqBody.push(parsedResult);
          appendToResults(parsedResult);
          setParsedResult(parsedResult);
          setIsLoading(false);
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      }

      const tagsResponse = await axios.post(
        "https://us-central1-inika-webpage.cloudfunctions.net/add_tags_db",
        {
          user: auth.currentUser.uid,
          tagsData: tagsReqBody,
        }
      );

      const prefResponse = await axios.post(
        "https://cheaply-shining-pup.ngrok-free.app/get_tag_preference_embed",
        {
          user: auth.currentUser.uid,
          query: tagPrefReqBody,
        }
      );
      setIsLoading(false);
      setParsedResults(prefResponse.data);
      setParsedResult(prefResponse.data);

      // const prefResponse = await axios.post(
      //   "http://localhost:8000/get_tag_preference_embed",
      //   {
      //     user: auth.currentUser.uid,
      //     query: tagPrefReqBody,
      //   }
      // );
      // console.log(prefResponse);
      // setIsLoading(false);
      // console.log(prefResponse.data);
      // setParsedResults(prefResponse.data);
      // setParsedResult(prefResponse.data);

      // console.log(tagsResponse.data.url_list);

      const tagOutputCards = document.getElementsByClassName("tag-output-card");

      for (let i = 0; i < tagOutputCards.length; i++) {
        tagOutputCards[i].style.display = "flex";
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      if (error.response) {
        if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Out of Tagging Tokens",
          });
        }
        if (error.response.status === 405) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Too many tags",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getImageByUrl = (url) => {
    if (parsedResults && parsedResults.length > 0) {
      // console.log(parsedResults)
      // console.log(url)
      return parsedResults.find((item) => item.url === url);
    } else {
      return null;
    }
  };

  const renderUploadedImageByIndex = (imageUrl) => {
    if (imageUrl) {
      return (
        <div className="uploaded-image-container">
          <img
            src={imageUrl}
            alt="Uploaded"
            // style={{ height: "270px", width: "200px" }}
          />
        </div>
      );
    }
    return null;
  };

  const history = useHistory();

  // Check if they are Autheticated
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // ...
    } else {
      // User is signed out
      // ...
      history.push("/login");
    }
  });

  // useEffects for loading the pref and setting it too
  useEffect(() => {
    handleTagsUpdate();
  }, [tags]);

  useEffect(() => {
    const fetchData = async () => {
      // Wait for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        // Call fetchFilters after the delay
        await fetchFilters();

        // Call handleTagsUpdate after fetchFilters
        handleTagsUpdate();
      } catch (error) {
        // Handle the error appropriately
        console.error("Error fetching filters:", error);
        // You can also show an error message to the user or take other actions
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <div className="inikaTagBG">
      <div className="main">
        <div className="tags-section">
          <div class="tag-input-section ">
            <div className="base-card tag-input-text">
              <span className="subtitle">Tags</span>

              <input
                className="tag-input-text-field"
                type="text"
                ref={tagInputRef}
                placeholder="Enter attributes here "
                onKeyPress={handleTagInputKeyPress}
              />
            </div>
          </div>
          {tags.length > 0 && (
            <TagFilter
              tags={tags}
              filters={filters}
              setFilters={setFilters}
              setTags={setTags}
            />
          )}

          <div class="btn-section">
            <button onClick={clearAllTag} class="btn">
              Clear all
            </button>
            {/* <button
              class="btn"
              onClick={handleSave}
              disabled={!filters.length > 0 || tags.length === 0}

            >
              Save preferences
            </button> */}
            <div className="dropup" style={{ marginLeft: "20%" }}>
              <button className="btn">{language}</button>
              <div className="dropup-content">
                <button value="English" onClick={handleLanguageChange}>
                  English
                </button>
                <button value="Arabic" onClick={handleLanguageChange}>
                  Arabic
                </button>
                <button value="Finnish" onClick={handleLanguageChange}>
                  Finnish
                </button>
                <button value="Danish" onClick={handleLanguageChange}>
                  Danish
                </button>
                <button value="Norwegian" onClick={handleLanguageChange}>
                  Norwegian
                </button>
                <button value="Swedish" onClick={handleLanguageChange}>
                  Swedish
                </button>
              </div>
            </div>
            <button
              class="btn"
              onClick={handleSubmit}
              disabled={!selectedImages || tags.length === 0}
            >
              Generate tags
            </button>
            <button
              class="btn"
              onClick={handleDescriptionGeneration}
              disabled={!selectedImages}
            >
              Generate Descriptions
            </button>
          </div>
        </div>

        {/* <div className='result-container'>
          <h3 className='subtitle'>Results</h3>
          <div className='result-container-images'>
            {selectedImages &&
              Object.entries(selectedImages).map((data, index) => (
                <div>
                  <div key={index} class='tag-output-card' id='tag-output-card'>
                    <div class='image-output-card'>
                      <div style={{ float: 'left', margin: '0 auto' }}>
                        {renderUploadedImageByIndex(data[1]['url'])}
                      </div>

                      <div className='hi-box'>
                <span>HI</span>
              </div>
                    </div>
                    <div class='output-tag-list-container'>
                      <div className='output-tag-list'>
                        {isLoading && <Shimmer />}

                        {parsedResults &&
                          !isLoading &&
                          getImageByUrl(data[1]['url']) &&
                          getImageByUrl(data[1]['url']).tags &&
                          Object.entries(
                            getImageByUrl(data[1]['url']).tags
                          ).map(
                            ([key, value], index) =>
                              // Check if the key is not 'image_url'
                              key !== 'url' && (
                                <div
                                  className='output-tag-list-item'
                                  key={index}
                                >
                                  <span>
                                    {key}: {value}
                                  </span>
                                </div>
                              )
                          )}

                        {!parsedResult && !isLoading && (
                          <div>
                            <h1>Input some tags to generate result.</h1>
                          </div>
                        )}
                      </div>


                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div> */}
        <div className="result-container">
          <h3 className="subtitle">Results</h3>
          <div className="result-container-images">
            {selectedImages &&
              Object.entries(selectedImages).map((data, index) => (
                <div key={index}>
                  <div className="tag-output-card" id="tag-output-card">
                    <div className="top-row">
                      <div className="image-output-card">
                        <div style={{ textAlign: "center", margin: "0 auto" }}>
                          {renderUploadedImageByIndex(data[1]["url"])}
                        </div>
                      </div>
                      <div className="output-tag-list-container">
                        <div className="output-tag-list">
                          {isLoading && tags.length === 1 && <Shimmer_one />}
                          {isLoading && tags.length === 2 && <Shimmer_two />}
                          {isLoading && tags.length > 2 && <Shimmer />}

                          {parsedResults &&
                            !isLoading &&
                            getImageByUrl(data[1]["url"]) &&
                            getImageByUrl(data[1]["url"]).tags &&
                            Object.entries(
                              getImageByUrl(data[1]["url"]).tags
                            ).map(
                              ([key, value], index) =>
                                key !== "url" && (
                                  <div
                                    className="output-tag-list-item"
                                    key={index}
                                  >
                                    <span>
                                      {key}: {value}
                                    </span>
                                  </div>
                                )
                            )}

                          {!parsedResult && !isLoading && (
                            <div>
                              <h1>Input some tags to generate result.</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {isLoadingDescription && (
                      <div
                        style={{
                          width: "90%",
                          textAlign: "center",
                          justifyContent: "center",
                          margin: "5%",
                        }}
                      >
                        <Shimmer_one />
                      </div>
                    )}

                    {descriptions.length > 0 && (
                      <div className="hi-box">
                        <span>{descriptions[index]["tags"]}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TagFilter = ({ tags, filters, setFilters, setTags }) => {
  const tagInputRefs = useRef(tags.map(() => React.createRef()));
  const [expIndex, setExpIndex] = useState(0);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editableTag, setEditableTag] = useState("");

  const handleTagInputKeyPress = (e, index) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      const newFilters = [...filters];
      const newValue = e.target.value.trim();

      // Check for duplicates before adding the new value
      if (!newFilters[index].includes(newValue)) {
        newFilters[index].push(newValue);
        setFilters(newFilters);
      }

      e.target.value = "";
    }
  };

  const handleFilterEdit = (tagIndex, filterIndex, newValue) => {
    const newFilters = [...filters];
    newFilters[tagIndex][filterIndex] = newValue;
    setFilters(newFilters);
  };

  const flipStyle = (tagIndex) => {
    return expIndex === tagIndex ? { transform: "scaleY(-1)" } : {};
  };

  const handleTagClick = (tagIndex, tag) => {
    setEditableTag(tag);
    setEditableIndex(tagIndex);
  };

  const handleTagChange = (event) => {
    setEditableTag(event.target.value);
  };

  const handleTagBlur = (index) => {
    setEditableIndex(null);
    // Here you can update the tag in your state or wherever it's stored
    // For example:

    const updatedTags = [...tags];
    const updatedFilters = [...filters];

    updatedTags[index] = editableTag;
    if (editableTag == "") {
      updatedTags.splice(index, 1);
      updatedFilters.splice(index, 1);
    }
    setTags(updatedTags);

    setEditableTag("");
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleTagBlur(index);
    }
  };

  return (
    <div className="tag-filter-input-section">
      {/* <h3 class="title">Tag preferences</h3> */}
      <div className="base-card tag-filter-input-text">
        <div className="filter-tag-list">
          {tags.map((tag, tagIndex) => (
            <div
              className={
                tagIndex === expIndex
                  ? "filter-tag-list-item-exp"
                  : "filter-tag-list-item"
              }
              key={tagIndex}
              style={{ width: "100%" }}
            >
              <div
                className="tag-filter-subtitle"
                onClick={() => {
                  if (expIndex === tagIndex) {
                    setExpIndex(-1);
                  } else {
                    setExpIndex(tagIndex);
                  }
                }} // Add onClick listener
              >
                {editableIndex === tagIndex ? (
                  <input
                    type="text"
                    value={editableTag}
                    onChange={handleTagChange}
                    onBlur={() => setEditableIndex(null)}
                    onKeyDown={(event) => handleKeyDown(event, tagIndex)}
                    autoFocus
                  />
                ) : (
                  <span onClick={() => handleTagClick(tagIndex, tag)}>
                    {tag}
                  </span>
                )}
                {/* <img
                  className="dropdown-icon"
                  src={dropdown_icon}
                  alt="+"
                  style={flipStyle(tagIndex)}
                ></img> */}
              </div>

              {/* <div className="tag-container">
                
                <input
                  className="tag-input-text-field"
                  type="text"
                  ref={tagInputRefs.current[tagIndex]}
                  placeholder={"Enter " + {tag} +  " here"}
                  onKeyPress={(e) => handleTagInputKeyPress(e, tagIndex)}
                  style={{ marginBottom: "8px" }}
                />
                <ul className="tag-list" id={tag}>
                  {filters[tagIndex]?.map((filter, filterIndex) => (
                    <FilterItem
                      key={filterIndex}
                      filter={filter}
                      onEdit={(newValue) =>
                        handleFilterEdit(tagIndex, filterIndex, newValue)
                      }
                    />
                  ))}
                </ul>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({ filter, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFilter, setEditedFilter] = useState(filter);

  const handleFilterClick = () => {
    setIsEditing(true);
  };

  const handleFilterChange = (e) => {
    setEditedFilter(e.target.value);
  };

  const handleFilterBlur = () => {
    setIsEditing(false);
    onEdit(editedFilter);
  };

  const handleFilterKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterBlur();
    }
  };

  return (
    <li className="tag-item">
      {isEditing ? (
        <input
          type="text"
          value={editedFilter}
          onChange={handleFilterChange}
          onBlur={handleFilterBlur}
          onKeyDown={handleFilterKeyDown}
          autoFocus
        />
      ) : (
        <span onClick={handleFilterClick}>{filter}</span>
      )}
    </li>
  );
};
